import staggerAttachments, { YES_OPTION, NO_OPTION } from 'projectDesign/components/projectConfiguration/fields/staggerAttachments';
import AdjustableTiltSystem from 'projectDesign/components/projectConfiguration/fields/adjustableTiltSystem';
import elevation from '../fields/elevation';
import buildingCode, { apiField as buildingCodeApiField } from '../fields/buildingCode';
import topographicalFactorKzt, { apiField as topographicalFactorKztApiField } from '../fields/topographicalFactorKzt';
import { apiField as velocityPressureExposureCofficientKzApiField } from '../fields/velocityPressureExposureCoefficientKz';
import { apiField as groundElevationFactorKeApiField } from '../fields/groundElevationFactorKe';
import { apiField as preferredSpanApiField } from '../fields/span';
import { apiField as windSpeedApiField } from '../fields/windSpeed';
import { apiField as railFinishApiField } from '../fields/railFinish';
import { apiField as meanRecurrenceApiField } from '../fields/meanRecurrenceInterval';
import { apiField as windExposureApiField } from '../fields/types/windExposure';
import { apiField as roofTypeApiField } from '../fields/roofType';
import { asce_710, asce_716, asce_722, isASCE705, isASCE710, isASCE716, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { GET_ENVIRONMENTAL_FACTORS, CLEAR_ENV_FACTORS_FOR, SET_PROJECT_OPTION, SET_CITY_NAME, REMOVE_OPTION_FROM_CONFIG, SET_FIELD_INVALID, OPEN_DRAWER_PAGE, SET_SEISMIC_DESIGN_CATEGORY, CLEAR_INVALID_FIELD, SET_FIELD_WARNING, TOGGLE_INPUT_UNIT, SET_FLYOUT_VERIFIED, REMOVE_OPTIONS_FROM_CONFIG, SET_PRODUCT_ID, SET_RAILS_PRODUCT_ID } from '../projectConfigurationActions';
import { areRequiredFieldsSet, buildingCodeHasChangedToAsce716or722, shouldRecalculateAsce716, shouldRecalculateNonAsce716or722 } from 'projectDesign/components/asce716switch/asce716switchHelper';
import { disableObstructionZoneView, enableObstructionsZoneView, disableWindZoneView } from '__editor/panelsEditor/components/roofZones/roofZonesHelper';
import { switchProjectToASCE716, switchProjectToNonASCE716, recalculateRoofZonesForPanels, getSpacings, getProjectRoofs } from 'projectDesign/components/asce716switch/asce716switch';
import { getBuildingHeight, getYesOrNo, isASCE716or722BuildingCode, isASCE716or722Selected, isASCE722BuildingCode, isFlatRoof } from '../projectConfiguration';
import { getMapCenter, setCenterForCurrentMap } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import { dispatch, state } from '__common/store';
import { getAreaByZip } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/getAreaByZip';
import { ICITY_STATE } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/cityModel';
import { CLEAR_WARNING, DELETE_PANELS_INSIDE_OF_ROOF, OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, REPLACE_PANELS_ON_ROOF, SET_LOCATION, SET_ROOF_ROOF_PITCH, UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF } from 'actions';
import { SET_TILTED_ROOF, SET_ROW_SPACING, SET_COLUMN_SPACING } from '__editor/panelsEditor/actions';
import residentalBuildingTypes from '../fields/residentalBuildingTypes';
import clampsChoice from '../fields/clampsChoice';
import { ulaClampsType } from '../fields/clampTypeChoice';
import rafterSpacing, { apiField as rafterSpacingApiField } from '../fields/rafterSpacing';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import riskCategory, { apiField as riskCategoryApiField, RiskCategory } from '../fields/riskCategory';
import { apiField as snowLoadApiField } from '../fields/types/snowLoad';
import { apiField as tiltApiField } from '../fields/tilt';
import { midClampsApiField } from '../fields/smClamps';
import { apiField as foundationTypeApiField, options as foundationTypeOptions } from '../fields/foundationType';
import { apiField as foundationLengthApiField, FOUNDATION_LENGTH } from '../fields/foundationLength';
import { apiField as foundationDiameterApiField } from '../fields/foundationDiameter';
import frontEdgeHeight, { apiField as frontEdgeHeightApiField } from '../fields/frontEdgeHeight';
import { apiField as smClampsChoicesApiField, smClampsOptions } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import { apiField as deadLoadFactorApiField } from '../fields/types/deadLoadFactor';
import { apiField as seismicSsApiField } from 'projectDesign/components/projectConfiguration/fields/types/seismicSs';
import { apiField as seismicS1ApiField } from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import { apiField as iceThicknessApiField } from '../fields/iceThickness';
import { apiField as limitDownPointLoadsApiField } from '../fields/limitDownPointLoads';
import { apiField as windOnIceApiField } from '../fields/windOnIce';
import { showErrorAlert, showWarningAlert } from '__common/modules/alerts';
import { isSFMFamily, isGFT, isResidentialProduct, isRMFamily, isULA, isRMIFIProduct, isMetalX, isSolarMount, isSfmInfinity, isRMGridFlex, isRM10, isNxtHorizon, isEcoFoot2Plus, isRM10Evolution, isSMTilt, isRM10orRM10Evo, isAscender, isRM5, isSMTiltPR, isRMAndEcofootFamily, isAdditionalUserInputEnabledProduct, isOnlyAsce716ProductFamily, isRMDT, isSMAscenderFlush, isSunframeMicroRail, isCanadianChangesProduct, isCommercialProduct, isSFMInfinity, isRmGridflex10, isGroundProduct, isNxtTilt, productHasAsce716BuildingCode, productHasAsce722BuildingCode, isSMRegular } from '__common/constants/products';
import { shouldUseStaggerAttachmentsForSfm, MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS } from 'projectDesign/components/projectConfiguration/constraints/constraints';
import { gable } from '__common/constants/buildingTypes';
import _, { get, round, toNumber } from 'lodash';
import { getDefaultFoundationLength } from '../configurations/GftConfig';
import { apiField as foundationTypeULAApiField, options as foundationTypeULAOptions } from '../fields/foundationTypeUla';
import { apiField as foundationDiameterULAApiField } from '../fields/foundationDiameterUla';
import { apiField as foundationScrewLengthApiField } from '../fields/foundationScrewLength';
import { apiField as parapetHeightInputApiField } from '../fields/rmIFIParapetHeight';
import { apiField as groundScrewManufacturerApiField } from '../fields/groundScrewManufacturerULA';
import { apiField as parapetHeightApiField } from '../fields/rm10ParapetHeight';
import { apiField as setbackDistanceApiField } from '../fields/setBackDistance';
import { apiField as allowMechanicalAttachmentsApiField } from '../fields/allowMechanicalAttachments';
import { apiField as attachmentOptimizationCriteriaApiField, AttachmentOptimization } from '../fields/attachmentOptimizationCriteria';
import { apiField as attachmentTypeApiField, AttachmentType } from '../fields/attachmentType';
import roofPsfLimit, { apiField as roofPsfLimitApiField } from '../fields/roofPsfLimit';
import { apiField as soilClassApiField, SOIL_CLASS } from '../fields/soilClass';
import { apiField as rmIFIParapetHeightApiField, rmIFIParapetHeightMetricField as rmIFIParapetHeightMetric, rmIFIParapetHeightField as rmIFIParapetHeight } from '../fields/rmIFIParapetHeight';
import totalWeightonRoofLimit, { apiField as totalWeightOnRoofLimitApiField } from '../fields/totalWeightOnRoofLimit';
import { apiField as shearAllowableApiField, shearAllowableForCustom, shearAllowableForUAnchor, shearAllowableForFlashloc, shearAllowableForOMG, shearAllowabledefaultValueKg, shearAllowabledefaultValue } from '../fields/shearAllowable';
import { apiField as upliftAllowableApiField, upliftAllowableForFlashloc, upliftAllowableForUAnchor, upliftAllowableForCustom, upliftAllowableForOMG, upliftAllowabledefaultValueKg, upliftAllowabledefaultValue } from '../fields/upliftAllowable';
import { apiField as ballastBlockWeightApiField, ballastBlockWeightField } from '../fields/ballastBlockWeight';
import { checkAdditionalUserInputs, checkFieldNotEmpty, checkParapetHeightRmIFIEvo, checkSeismicS1, checkSeismicSs, checkSetbackDistance, getBuildingHeightLimits, getLongestBuildingLengthLimits, getSetbackLimits, getShortestBuildingLengthLimits, getSnowLoadLimits, getWindSpeedLimits, } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { getSeismicDesignCategory } from '__common/calculations/seismic_design_category';
import { makeAllRoofsEmpty, makeRoofEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { apiField as frostDepthApiField } from '../fields/frostDepthGft';
import { apiField as solarhookapifield } from '../fields/smSolarhooksAttachments';
import { apiField as solarhooksOrTileReplacementApiField } from '../fields/smTileReplacementsOrSolarhooks';
import { apiField as smRooftypeApiField } from '../fields/roofType';
import { apiField as materialThicknessApiField } from '../fields/roofMaterialThicknessSolarMountFlush';
import { additionalUserInputsPage, additionalUserInputsPageDependencyFields, FIELD_FLYOUT_MAPPING, locationAndLoadsPageApiFields, MidEndClampChoice, railSystemPageApiFields, removeUpslopeRail, RoofMaterialThickness, RoofSubstrate, RoofType, TileReplacementOrSolarhooks, ULAClampLocationType, ULARailType } from './constants';
import { apiField as centralSupportApiField } from '../fields/centralSupport';
import { apiField as anchorTypeApiField } from '../fields/uAnchorType';
import buildingHeight, { apiField as buildingHeightApiField } from '../fields/buildingHeight';
import { apiField as ballastBlockHalfWeightApiField, ballastBlockHalfWeightField } from 'projectDesign/components/projectConfiguration/fields/ballastHalfBlockWeight';
import roundingHalfBlock from 'projectDesign/components/projectConfiguration/fields/roundingHalfBlock'
import { getMaxSetBack, getSeismicSetback } from '__editor/panelsEditor/components/roofZones/utils/setBackDistanceCollisionDetections';
import { filterOutPanelsNotInsideRoofEdges } from 'projectDesign/rmGridflexBlankMapUtils';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { apiField as metalXAttachmentTypeField, getAttachmentTypeOptions, ATTACHMENT_TYPES } from '../fields/metalXAttachmentType';
import { apiField as metalXRoofTypeApiField, ROOF_TYPES } from '../fields/metalXRoofType';
import { apiField as mxMaterialThicknessApiField, MATERIAL_THICKNESS, configStandingSeam, configCorrugated, configRPanel } from '../fields/metalXMaterialThickness';
import { apiField as roofSubstrateApiField } from '../fields/roofSubstrate';
import { isBoolean } from 'lodash';
import nbccWindPressure, { apiField as nbccWindPressureApiField } from '../fields/nbccWindPressure';
import { isCanadianZipcode as checkCanadianZipcode, validateZipCode, changeSetbackDistanceWarning, isOsbOrPlywood, isSpanMultipleOfRafterSpacing, validateRafterSpacing, validateSpans, validateSpansMinMax, showTornadoSpeedWarning } from './validation';
import { apiField as longestBuildingLengthApiField } from '../fields/longestBuildingLength';
import { apiField as shortestBuildingLengthApiField } from '../fields/shortestBuildingLength';
import { getEditorCenter } from '__editor/panelsEditor/components/background/background';
import { deleteAllPanels, removePanelsWithNoSiblings } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import { clearRTree, insertPanelIntoRtree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { getPanelCollisionBounds } from '__editor/panelsEditor/components/panels/panels';
import { checkObstructionZoneForPanels } from '__editor/panelsEditor/components/obstructions/obstructionsCollisions';
import store from '__common/store';
import { apiField as maxDownPointLoadToBeAllowedApiField, maxDownPointLoadToBeAllowedField } from '../fields/maxDownPointLoadToBeAllowed';
import { checkCentralSupportConditionForSnowloadAndHeight, updateProjectOptionsOnModuleDimsChange, disableMandatoryMidSupportCondition } from './updateModuleDims';
import { clampLocation } from '../fields/clampLocations';
import { calculateKe, calculateKz, get_numerical_coefficient, get_design_life_factor } from './coefficients';
import designLifeFactorFc from '../fields/designLifeFactorFc';
import numericalCoefficient from '../fields/numericalCoefficient';
import meanRecurrenceInterval from '../fields/meanRecurrenceInterval';
import { apiField as railTypeApiField } from '../fields/railType';
import { applyCentralSupportForRM5, applyShortestBuildingLength, applyRM5Revamp, applyDeadloadFactorLimitChanges, needNewZoneClassification, applyEcoFoot2PlusRM10andEvoSetbackChanges, applyRemoveMRI25ForEF, greaterThanEqualToProjectVersion, applyKrinnerGroundScrewULA, adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';
import parapetHeightNumeric from '../fields/parapetHeightNumeric';
import railLengthForThermalGap from '../fields/railLengthForThermalGap';
import allowThermalGap from '../fields/allowThermalGap';
import { cmsToFeets, cmsToInches, feetsToCms, inchesToCms, kgsToLbs, kmsToMiles, kpaToPsf, lbsToKgs, metersToCms, milesToKms, psfToKpa } from '__common/calculations/unitConversions';
import { updateRoofDimensions as updateRoofDimensionsBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { updateRoofDimensions as updateRoofDimensionsGoogleMap } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { apiField as buildingStoreyApiField } from '../fields/buildingStorey';
import { buildingHeightCheck } from '../constraints/constraints';
import { shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { anyPanelsDrawn, anyRoofHasManualAttachments, deleteManualAttachmentsOnPanels } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { apiField as allowManualAttachmentsApiField } from '../fields/allowManualAttachments';
import { apiField as mandatorymidsupportApiField } from '../fields/mandatorymidsupport';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { isBingMap, isGoogleMap } from '__common/constants/map';
import { apiField as rainLoadApiField } from '../fields/rainLoad';
import { metersToInches } from 'maths';
import { ADDITIONAL_USER_INPUTS_PAGE, LOCATION_AND_LOADS_PAGE } from './projectConfigurationDrawerPages';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSd1 from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeed, { metricTornadoSpeedField } from '../fields/tornadoSpeed';
import longTransitionPeriods from '../fields/longTransitionPeriods';
import longestRowLength from '../fields/longestRowLength';
import shortestRowLength from '../fields/shortestRowLength';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { apiField as railArrangementTypeApiField, options as railArrangementTypeOptions } from '../fields/railArrangementType';
import { apiField as topChordApiField, options as topChordOptions } from '../fields/topChord';
import { endClampsApiField } from '../fields/smClamps';
import { apiField as railClampOptionApiField, NXTRailClampOption } from "../fields/railClampOption"
import { apiField as railDirectionApiField } from "../fields/railDirection";
import { apiField as addRoofPadsEverywhere } from '../fields/roofPadsEveryWhereField';
import { apiField as useFriction } from '../fields/useFrictionField';



const SDC_AFFECTING_FIELDS = [seismicSsApiField, seismicS1ApiField, buildingCodeApiField, riskCategoryApiField, soilClassApiField];

const PRO_SERIES = '11';
const STANDARD = '01';
export const RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS = [...SDC_AFFECTING_FIELDS, parapetHeightInputApiField, setbackDistanceApiField];
const RM_ECOFOOT_SETBACK_AFFECTING_FIELDS = [...RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS, parapetHeightApiField, buildingHeightApiField, seismicSds.apiField,seismicSd1.apiField]

export const RESTRICTED_ROOF_PITCH_SDC_VALUES = ['C', 'D', 'E', 'F'];
let activeCheckboxes = {};

export const getActiveCheckboxes = () => {
  return activeCheckboxes;
};

export const addActiveCheckbox = (field: string, value: any) => {
  activeCheckboxes[field] = value;
};

export const clearActiveCheckboxes = () => {
  activeCheckboxes = {};
};

export const disableFlyoutsVerified = (field, productId) => {
  const { projectConfiguration: { projectEnvConfig, formState: { invalidFields }, verifiedFlyouts } } = state();

  // check zipcode everytime
  if (!validateZipCode(projectEnvConfig.zipcode)) {
    dispatch(SET_FLYOUT_VERIFIED(LOCATION_AND_LOADS_PAGE, false, true));
    return;
  }

  for (const { fieldName } of invalidFields) {
    dispatch(SET_FLYOUT_VERIFIED(FIELD_FLYOUT_MAPPING[fieldName], false, true));
  }
  // check additionalInputFields everytime
  if (isAdditionalUserInputEnabledProduct(productId) && (additionalUserInputsPageDependencyFields.includes(field) || additionalUserInputsPage.includes(field))) {
    for (const key of additionalUserInputsPage) {
      if (!projectEnvConfig[key] && !verifiedFlyouts[ADDITIONAL_USER_INPUTS_PAGE].error) {
        dispatch(SET_FLYOUT_VERIFIED(ADDITIONAL_USER_INPUTS_PAGE, false, true));
        return;
      }
    }
  }
}

function unCheckFlyoutVerification(field: string, productId: number){
  const dependencyFields = {
    'module-selection': [],
    'location-and-loads': locationAndLoadsPageApiFields,
    'rail-system': railSystemPageApiFields,
    'additional-user-inputs': additionalUserInputsPage
  }

  if (!isAdditionalUserInputEnabledProduct(productId)) {
    delete dependencyFields['additional-user-inputs']
  } else if (additionalUserInputsPageDependencyFields.includes(field)) {
    dispatch(SET_FLYOUT_VERIFIED('additional-user-inputs', false))
  }

  for (const [key, value] of Object.entries(dependencyFields)) {
    if (value.includes(field)) {
      dispatch(SET_FLYOUT_VERIFIED(key, false))
    }
  }
  return
}

export const checkSetbackDistanceAffected = (field: string, value: any, currentEnvConfig: projectEnvConfig, resolve: Function) => {

  const { drawingManager: { roofs }, roofsSelector: { mapType }, settings: { panelWidth, panelHeight }, projectConfiguration: { productId, inputUnit, formState: { invalidFields: allInvalidFields }, projectEnvConfig, projectVersion } } = state();

  if (mapType !== 'white') return { isValid: false };

  const invalidFields = new Set<string>();
  RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS.map(field => !checkFieldNotEmpty(projectEnvConfig[field]) && invalidFields.add(field));
  allInvalidFields.map(({ fieldName }) => RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS.includes(fieldName) && invalidFields.add(fieldName));
  if (invalidFields.size) return { isValid: false };

  let isValid = false;
  if (field === seismicSsApiField) {
    isValid = checkSeismicSs(value);
  } else if (field === seismicS1ApiField) {
    isValid = checkSeismicS1(value);
  } else if (field === setbackDistanceApiField) {
    isValid = checkSetbackDistance(value, false);
  } else if (field === parapetHeightInputApiField) {
    isValid = checkParapetHeightRmIFIEvo(value, inputUnit, false);
  }

  if (!isValid) return { isValid };

  const newSetbackDistance = getMaxSetBack(currentEnvConfig, inputUnit, productId, projectVersion);

  const anyRoofAffected = roofs && Object.keys(roofs).some(roofId => {
    const { blank_map_building_length: length, blank_map_building_width: width, metersPerPixel, bgScale, panels } = roofs[roofId];

    if (!bgScale) return false;
    const minSizeforMin1Panel = metersToFeets(((newSetbackDistance / metersPerPixel) * 2 + Math.max(panelWidth, panelHeight)) * metersPerPixel);
    const minSize = Math.ceil(minSizeforMin1Panel);
    let [newLength, newWidth] = [length, width];
    if (length < minSize) newLength = minSize;
    if (width < minSize) newWidth = minSize;

    if (length !== newLength || width !== newWidth) {
      dispatch(UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF(Number(roofId), newLength, newWidth));
    }

    const filteredPanels = filterOutPanelsNotInsideRoofEdges({ length: newLength, width: newWidth, setbackDistance: newSetbackDistance, metersPerPixel, bgScale, panelWidth, panelHeight, panels });
    return filteredPanels.length !== panels.length;
  });

  if (!anyRoofAffected) return { isValid: false };

  const onConfirmCallback = () => {
    Object.keys(roofs).map(roofId => {
      const { blank_map_building_length: length, blank_map_building_width: width, metersPerPixel, bgScale, panels } = roofs[roofId];

      const minSizeforMin1Panel = metersToFeets(((newSetbackDistance / metersPerPixel) * 2 + Math.max(panelWidth, panelHeight)) * metersPerPixel);
      const minSize = Math.ceil(minSizeforMin1Panel);
      let [newLength, newWidth] = [length, width];
      if (length < minSize) newLength = minSize;
      if (width < minSize) newWidth = minSize;

      const filteredPanels = filterOutPanelsNotInsideRoofEdges(
        { length: newLength, width: newWidth, setbackDistance: newSetbackDistance, metersPerPixel, bgScale, panelWidth, panelHeight, panels });
      if (filteredPanels.length !== panels.length) {
        dispatch(REPLACE_PANELS_ON_ROOF(filteredPanels, Number(roofId)));
      }
    });
    resolve(true);
  };

  const onCancelCallback = () => resolve(false);
  return { isValid: true, onConfirmCallback, onCancelCallback };
};

export const checkRoofPitchAffected = (field: string, value: any, currentEnvConfig: projectEnvConfig, resolve: Function) => {
  let shouldShowClearRoofsModal = false;
  let roofPitchLimit = 7;
  const { projectConfiguration: { seismicDesignCategory, area: areaAddress, projectEnvConfig: { allow_mechanical_attachments } }, drawingManager: { roofs } } = state();
  if (isASCE716or722(currentEnvConfig.building_code) && SDC_AFFECTING_FIELDS.includes(field)) {
    let isValid = false;
    if (field === seismicSsApiField) {
      isValid = checkSeismicSs(value);
    } else if (field === seismicS1ApiField) {
      isValid = checkSeismicS1(value);
    } else {
      isValid = checkFieldNotEmpty(value);
    }

    if (!isValid) return { isValid };

    const { seismicDesignCategory: updatedSeismicDesignCategory } = getSeismicDesignCategory(currentEnvConfig);
    if (seismicDesignCategory !== updatedSeismicDesignCategory) {
      dispatch(SET_SEISMIC_DESIGN_CATEGORY(updatedSeismicDesignCategory));
    }
    shouldShowClearRoofsModal = RESTRICTED_ROOF_PITCH_SDC_VALUES.includes(updatedSeismicDesignCategory) &&
      !RESTRICTED_ROOF_PITCH_SDC_VALUES.includes(seismicDesignCategory);
  } else if (isASCE710(currentEnvConfig.building_code)) {
    const stateString = areaAddress.split(',')[1];
    shouldShowClearRoofsModal = [buildingCodeApiField, 'zipCode'].includes(field) && stateString && stateString.trim() === 'CA';
  }

  if (shouldShowClearRoofsModal) {
    roofPitchLimit = 3;
  } else if (currentEnvConfig.allow_mechanical_attachments && (currentEnvConfig.attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM || currentEnvConfig.allow_mechanical_attachments !== allow_mechanical_attachments)) {
    shouldShowClearRoofsModal = true
    roofPitchLimit = 5;
  }

  const anyRoofPitchExceedsLimit = roofs && Object.keys(roofs).some(roofId => {
    const roofPitch = roofs[roofId]?.roofPitch;
    return roofPitch && Number(roofPitch) > roofPitchLimit;
  });

  shouldShowClearRoofsModal = shouldShowClearRoofsModal && anyRoofPitchExceedsLimit;

  if (!shouldShowClearRoofsModal) return { isValid: false };

  const onConfirmCallback = () => {
    Object.keys(roofs).map(roofId => {
      if (Number(roofs[roofId].roofPitch) > roofPitchLimit) {
        makeRoofEmpty(Number(roofId));
        dispatch(SET_ROOF_ROOF_PITCH(Number(roofId), '0'));
      }
    });
    resolve(true);
  };

  const onCancelCallback = () => resolve(false);
  return { isValid: true, onConfirmCallback, onCancelCallback };
};

export const checkManualAttachmentsAffected = (field: string, value: any, currentEnvConfig: projectEnvConfig, resolve: Function) => {
  const { projectConfiguration: { projectEnvConfig: { allow_mechanical_attachments }, productId } } = state();
  let isValid = false;
  if (field === allowMechanicalAttachmentsApiField && !currentEnvConfig.allow_mechanical_attachments && currentEnvConfig.allow_manual_attachments && currentEnvConfig.allow_mechanical_attachments !== allow_mechanical_attachments) {
    isValid = anyRoofHasManualAttachments(0);
  }
  if (!isValid) return { isValid };
  const onConfirmCallback = () => {
    dispatch(SET_PROJECT_OPTION(allowManualAttachmentsApiField, 0));
    deleteManualAttachmentsOnPanels(productId);
    resolve(true);
  }
  const onCancelCallback = () => resolve(false);
  return { isValid, onConfirmCallback, onCancelCallback };
}

export const getRMIFICheckPromise = (field: string, value: any, productId: number, prevEnvConfig: projectEnvConfig): Promise<boolean> => {
  // Promise resolves to :
  // 1. True: if Project Option Update should continue
  // 2. False: if Project Option shouldn't update   
  return new Promise((resolve, _) => {
    if ((!isRMIFIProduct(productId) && !isRM10Evolution(productId) && !isRM5(productId)) || prevEnvConfig[field] === value) {
      return resolve(true);
    }
    let currentEnvConfig = { ...prevEnvConfig, [field]: value };
    if (field === buildingCodeApiField && isASCE710(value) && prevEnvConfig[soilClassApiField] === SOIL_CLASS.D_DEFAULT) {
      currentEnvConfig = { ...currentEnvConfig, [soilClassApiField]: SOIL_CLASS.D };
    }

    const { isValid: roofPitchAffected, onConfirmCallback: onConfirmCallbackRoofPitch, onCancelCallback: onCancelCallbackRoofPitch } = checkRoofPitchAffected(field, value, currentEnvConfig, resolve);
    if (roofPitchAffected) {
      const warning_message = field === attachmentTypeApiField && value === AttachmentType.UNIRAC_FLASHLOC_RM ?
        'Modifying the "ATTACHMENT TYPE" field value to Unirac Flashloc RM will rest your Roof pitch to 0 and clear out your Roof Area(s) panels if any, as maximum allowable roof pitch for Unirac flashloc RM is 5. Continue?' :
        `Modifying the "${field.toLocaleUpperCase().replace(/_/g, ' ')}" field value will reset your Roof Pitch to 0 and clear out your Roof Area(s) panels if any. Continue?`
      dispatch(OPEN_DRAWER_PAGE(null));
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          'Roof Pitch will be reset!',
          warning_message,
          onConfirmCallbackRoofPitch,
          onCancelCallbackRoofPitch,
        ),
      );
      return;
    }
    const { isValid: setbackDistanceAffected, onConfirmCallback: onConfirmCallbackSetback, onCancelCallback: onCancelCallbackSetback } = checkSetbackDistanceAffected(field, value, currentEnvConfig, resolve);
    if (setbackDistanceAffected) {
      dispatch(OPEN_DRAWER_PAGE(null));
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          'Setback Distance Increased!',
          `Modifying the ${field} field value will adjust the setback distance and remove some of the panels in your Roof Area(s). Continue?`,
          onConfirmCallbackSetback,
          onCancelCallbackSetback,
        ),
      );
    }
    const { isValid: manualAttachmentsAffected, onConfirmCallback: onConfirmCallbackManualAttachments, onCancelCallback: onCancelCallbackManualAttachments } = checkManualAttachmentsAffected(field, value, currentEnvConfig, resolve);
    if (manualAttachmentsAffected) {
      dispatch(OPEN_DRAWER_PAGE(null));
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          'Are you sure you want to disallow mechanical attachments?',
          'All manual attachment markers will be lost!',
          onConfirmCallbackManualAttachments,
          onCancelCallbackManualAttachments
        )
      );
    } else resolve(true);
  });
};


export const updateProjectOption = (
  field: string,
  value: any,
  zipcode: string,
  productId: number,
  currentEnvConfig: projectEnvConfig,
) => {

  // if update was discarded by user, dont update the project option
  getRMIFICheckPromise(field, value, productId, currentEnvConfig)
    .then(allowUpdate => {
      if (allowUpdate) applyProjectOptionUpdate(field, value, zipcode, productId, currentEnvConfig);
    })
    .then(() => disableFlyoutsVerified(field, productId))
};

function updateNon716Panels(productId: number) {
  const { roofsSelector: { mapType }, projectConfiguration: { projectEnvConfig: { building_type }, projectVersion } } = state();
  const roofs = getProjectRoofs();

  if (roofs) {
    const editorCenter = getEditorCenter();
    const buildingHeightFt = getBuildingHeight();

    Object.keys(roofs).forEach(roofKey => {
      const roofId = Number(roofKey);
      const roof: drawingManagerRoof = roofs[roofId];
      const panels = roof.panels;
      const rowSpacing = roof.rowSpacing;
      const { columnSpacing } = getSpacings(productId, projectVersion);


      if (!panels || panels.length === 0) {
        return;
      }

      const metersPerPixel = roof.metersPerPixel;

      recalculateRoofZonesForPanels(
        store,
        panels,
        roof,
        mapType,
        productId,
        editorCenter,
        buildingHeightFt,
        building_type,
        projectVersion
      ).then((panelsWithReassignedZones) => {
        let updatedPanels = removePanelsWithNoSiblings(panelsWithReassignedZones);
        updatedPanels = checkObstructionZoneForPanels(updatedPanels, roofId, metersPerPixel, editorCenter.x, editorCenter.y);
        clearRTree();

        updatedPanels.map(panel => {
          const bounds = getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
          insertPanelIntoRtree(bounds);
        });
        dispatch(REPLACE_PANELS_ON_ROOF(updatedPanels, roofId));
      });

    });
  }
}

export const applyProjectOptionUpdate = (
  field: string,
  value: any,
  zipcode: string,
  productId: number,
  currentEnvConfig: projectEnvConfig,
) => {
  const previousEnvConfig = {
    ...currentEnvConfig,
  };
  const { user: { isStaff }, projectConfiguration: { inputUnit, projectVersion, projectEnvConfig: { rail_arrangement_type } }, moduleSelector: { modelData: { height: moduleLength } }, drawingManager:{roofs} } = state();
  const isCanadianZipcode = checkCanadianZipcode(zipcode);

  unselectRadioOption(value);

  clearActiveCheckboxes();
  addActiveCheckbox(field, currentEnvConfig[field]);
  unCheckFlyoutVerification(field, productId)
  if((isRM10orRM10Evo(productId) || isRMGridFlex(productId) || isEcoFoot2Plus(productId)  || isRmGridflex10(productId) ) 
  && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['changing_soil_class_to_d_default_for_building_code_asce_716'])){      
    if(field === buildingCodeApiField){
      if(isASCE716or722(value)){
        dispatch(SET_PROJECT_OPTION(soilClassApiField, SOIL_CLASS.D_DEFAULT));
      }
      else {
        dispatch(SET_PROJECT_OPTION(soilClassApiField, SOIL_CLASS.D));
      }
    }
  }

  if (field === elevation.apiField || field === windSpeedApiField || (field === buildingHeightApiField) && !isAscender(productId)) {
    dispatch(SET_PROJECT_OPTION(field, parseInt(value, 10).toFixed(0)));
  }
  else if (field === AdjustableTiltSystem.apiField) {
    (anyPanelsDrawn()) ? 
    dispatch(OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
      'Change adjustable tilt',
      'Changing adjustable tilt will remove all panels. Are you sure?',
    () => {
        makeAllRoofsEmpty();
        productChange(field, value)
    },
      null
    )) : productChange(field, value)
  }
  else if (field === staggerAttachments.apiField || field === roundingHalfBlock.apiField) {
    let val: boolean;

    if (value === 'false') {
      val = false;
    } else {
      val = true;
    }
    dispatch(SET_PROJECT_OPTION(field, val));
  }
  else if ([roofPsfLimitApiField, shearAllowableApiField, upliftAllowableApiField, ballastBlockHalfWeightApiField, ballastBlockWeightApiField, rainLoadApiField].includes(field)) {
    if (value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 1) {
      dispatch(SET_PROJECT_OPTION(field, parseFloat(value).toFixed(1)));
    } else {
      dispatch(SET_PROJECT_OPTION(field, value));
    }
  }
  else if (field === totalWeightOnRoofLimitApiField) {
    dispatch(SET_PROJECT_OPTION(field, parseInt(value, 10)));
  }
  else if (field === setbackDistanceApiField) {
    if (isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRMGridFlex(productId)) {
      dispatch(SET_PROJECT_OPTION(field, parseFloat(value.toFixed(1))));
    } else {
      dispatch(SET_PROJECT_OPTION(field, parseInt(value, 10)));
    }
  } else if ((isRMGridFlex(productId) || isRM10(productId) || isRM10Evolution(productId)) && field === deadLoadFactorApiField) {
    if (value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 3) {
      dispatch(SET_PROJECT_OPTION(field, parseFloat(value).toFixed(3)));
    } else {
      dispatch(SET_PROJECT_OPTION(field, value));
    }
  } else if (field === centralSupportApiField && isBoolean(value)) {
    if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId)) {
      if (isMetricUnit(inputUnit)) {
        if (moduleLength > inchesToCms(87) && previousEnvConfig.snow_load > psfToKpa(20) || previousEnvConfig.snow_load > psfToKpa(60) || moduleLength > inchesToCms(90)) {
          return showErrorAlert(`Central support is required for module length > ${inchesToCms(87)}cm and snow load > ${psfToKpa(20)}kpa or snow load > ${psfToKpa(60)}kpa or module length > ${inchesToCms(90)}cm.`);
        }
      }
      if (checkCentralSupportConditionForSnowloadAndHeight(previousEnvConfig.snow_load, store.getState().moduleSelector.modelData.height)) {
        return showErrorAlert('Central support is required for module length >= 87in and snow load > 20psf or snow load > 60psf or module length >= 90in.');
      }
    }
    dispatch(SET_PROJECT_OPTION(field, value ? 1 : 0));
  }
  else if (field === addRoofPadsEverywhere && isBoolean(value)) {
    dispatch(SET_PROJECT_OPTION(field, value ? 1 : 0));
  }
  else if (field === useFriction && isBoolean(value)) {
    dispatch(SET_PROJECT_OPTION(field, value ? 1 : 0));
  }
  else if (field === mandatorymidsupportApiField && isBoolean(value)) {
    dispatch(SET_PROJECT_OPTION(field, value ? 1 : 0));
  }
  else if (field == snowLoadApiField) {
    if (isAscender(productId) || isMetricUnit(inputUnit)) {
      if (value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2) {
        dispatch(SET_PROJECT_OPTION(field, parseFloat(value).toFixed(2)));
      } else {
        dispatch(SET_PROJECT_OPTION(field, value));
      }
    }
    else {
      dispatch(SET_PROJECT_OPTION(field, parseInt(String(value))));
    }
  }
  else if (field === residentalBuildingTypes.apiField) {
    setProperRoofPitchForResidentialsASCE716(currentEnvConfig.building_code, value);
  }
  else if (field === railLengthForThermalGap.apiField && !value) {
    dispatch(SET_FIELD_INVALID(railLengthForThermalGap.apiField, `Rail Length for each thermal gap must be selected`));
  }
  else if (field === seismicSsApiField) {
    dispatch(SET_PROJECT_OPTION(seismicSsApiField, parseFloat(value.toFixed(3))));
    if (value <= 0 || value > 3.1) {
      dispatch(SET_FIELD_INVALID(seismicSsApiField, 'Seismic Factor(SS) must be a positive value not exceeding 3.1'));
    }
  }
  else if (field === seismicS1ApiField) {
    dispatch(SET_PROJECT_OPTION(seismicS1ApiField, parseFloat(value.toFixed(3))));
    if (value < 0 || value > 3.1) {
      dispatch(SET_FIELD_INVALID(seismicS1ApiField, 'Seismic Factor(S1) must lie between 0 and 3.1'));
    }
  }
  else if (field === seismicSds.apiField) {
    dispatch(SET_PROJECT_OPTION(seismicSds.apiField, parseFloat(value.toFixed(3))));
    if (value < seismicSds.min || value > seismicSds.max) {
      dispatch(SET_FIELD_INVALID(seismicSds.apiField, `Seismic Factor(Sds) must lie between ${seismicSds.min} and ${seismicSds.max}`));
    }
  }
  else if (field === seismicSd1.apiField) {
    dispatch(SET_PROJECT_OPTION(seismicSd1.apiField, parseFloat(value.toFixed(3))));
    if (value < seismicSd1.min || value > seismicSd1.max) {
      dispatch(SET_FIELD_INVALID(seismicSd1.apiField, `Seismic Factor(Sd1) must lie between ${seismicSd1.min} and ${seismicSd1.max}`));
    }
  }
  else if (field === tornadoSpeed.apiField) {
    const { apiField, min, max } = isMetricUnit(inputUnit) ? metricTornadoSpeedField : tornadoSpeed;
    dispatch(SET_PROJECT_OPTION(apiField, parseFloat(value.toFixed(3))));
    if (value < min || value > max) {
      dispatch(SET_FIELD_INVALID(apiField, `Tornado Speed must lie between ${min} and ${max}`));
    }
  }
  else if (field === longTransitionPeriods.apiField) {
    const { apiField, min, max } = longTransitionPeriods;
    dispatch(SET_PROJECT_OPTION(apiField, parseInt(value)));
    if (value < min || value > max) {
      dispatch(SET_FIELD_INVALID(apiField, `Long-Period Transition Periods must lie between ${min} and ${max}`));
    }
  }
  else if (field === longestRowLength.apiField) {
    const { apiField, min, max } = longestRowLength;
    dispatch(SET_PROJECT_OPTION(apiField, parseFloat(value)));
    if (value < min || value > max) {
      dispatch(SET_FIELD_INVALID(apiField, `Longest Row Length must lie between ${min} and ${max}`));
    }
  }
  else if (field === shortestRowLength.apiField) {
    const { apiField, min, max } = shortestRowLength;
    dispatch(SET_PROJECT_OPTION(apiField, parseFloat(value)));
    if (value < min || value > max) {
      dispatch(SET_FIELD_INVALID(apiField, `Shortest Row Length must lie between ${min} and ${max}`));
    }
  }
  else if (field === nbccWindPressureApiField) {
    dispatch(SET_PROJECT_OPTION(nbccWindPressureApiField, parseFloat(value.toFixed(2))));
    if (value < nbccWindPressure.min || value > nbccWindPressure.max) {
      dispatch(SET_FIELD_INVALID(nbccWindPressureApiField, `Wind Pressure must lie between ${nbccWindPressure.min} and ${nbccWindPressure.max}`));
    }
  }
  else {
    dispatch(SET_PROJECT_OPTION(field, value));
  }

  if (field === allowThermalGap.apiField) {
    if (value === 0) {
      dispatch(SET_PROJECT_OPTION(railLengthForThermalGap.apiField, null));
    }
    else {
      dispatch(SET_FIELD_INVALID(railLengthForThermalGap.apiField, `Rail Length for each thermal gap must be selected`));
    }
  }
  if (isRMGridFlex(productId) && field===snowLoadApiField){
    dispatch(SET_PROJECT_OPTION(mandatorymidsupportApiField, disableMandatoryMidSupportCondition(store.getState().moduleSelector.modelData.height, value)));
  }

  if ((isResidentialProduct(productId) || isRMFamily(productId) || isEcoFoot2Plus(productId) || isGroundProduct(productId)) && field === buildingCode.apiField) {
    if(!isGroundProduct(productId)) {
      if (isASCE716or722BuildingCode(value)) {
        dispatch(SET_PROJECT_OPTION('mean_recurrence_interval', '50'));
        dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, get_design_life_factor(50)));
        setResidentialsExtraInputsValueForAsce716();
      } else {
        !isCanadianZipcode && dispatch(SET_PROJECT_OPTION('mean_recurrence_interval', '25'));
        !isCanadianZipcode && dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, get_design_life_factor(25)));
        unsetResidentialsExtraInputsValueForAsce716(productId);
      }
    }
    if(isASCE722BuildingCode(value))
      setExtraInputsValueForAsce722();
    else
      unsetExtraInputsValueForAsce722();

    if(isEcoFoot2Plus(productId) && applyRemoveMRI25ForEF(projectVersion))
      dispatch(SET_PROJECT_OPTION('mean_recurrence_interval', '50'));
  }
  
  if (isAdditionalUserInputEnabledProduct(productId) && isStaff ) {
    if (field === windExposureApiField && value !== previousEnvConfig[windExposureApiField]) {
      dispatch(SET_PROJECT_OPTION(velocityPressureExposureCofficientKzApiField, calculateKz(value, previousEnvConfig[buildingHeightApiField], zipcode, '', inputUnit)));
    }
    if (field === buildingHeightApiField && value !== previousEnvConfig[buildingHeightApiField]) {
      dispatch(SET_PROJECT_OPTION(velocityPressureExposureCofficientKzApiField, calculateKz(previousEnvConfig[windExposureApiField], value, zipcode, '', inputUnit)));
    }
    if (field === elevation.apiField && value !== previousEnvConfig[elevation.apiField]) {
      dispatch(SET_PROJECT_OPTION(groundElevationFactorKeApiField, calculateKe(value, isASCE716or722(previousEnvConfig[buildingCodeApiField]), inputUnit)));
      dispatch(SET_PROJECT_OPTION(numericalCoefficient.apiField, get_numerical_coefficient(previousEnvConfig[buildingCodeApiField], value, inputUnit)));
    }
    if (field === buildingCodeApiField && value !== previousEnvConfig[buildingCodeApiField]) {
      dispatch(SET_PROJECT_OPTION(groundElevationFactorKeApiField, calculateKe(previousEnvConfig[elevation.apiField], isASCE716or722(value), inputUnit)));
      dispatch(SET_PROJECT_OPTION(numericalCoefficient.apiField, get_numerical_coefficient(value, previousEnvConfig[elevation.apiField], inputUnit)));
    }
    if (field === designLifeFactorFc.apiField && value !== previousEnvConfig[designLifeFactorFc.apiField]) {
      dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, value));
    }
    if (field === meanRecurrenceInterval.apiField && value !== previousEnvConfig[meanRecurrenceInterval.apiField]) {
      dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, get_design_life_factor(value)))
    }
    const isOk = checkAdditionalUserInputs(currentEnvConfig, false);
    if (!isOk && additionalUserInputsPage.includes(field)) {
      dispatch(SET_FIELD_INVALID(field, "Please enter a valid value or reset to default"));
    }

  }

  if (isSMTilt(productId)) {
    if (field === smClampsChoicesApiField) {
      updateProjectOptionsOnModuleDimsChange();
    }
  }

  // we need both previous and current values .
  // It's because we check if the field value has changed
  // but also we check if other required fields are already set.
  // For some products user has to choose them, for some they are set automatically.
  // That's why we check if we should recalculate after the other fields has been set.

  const buildingHeightHasChanged = (
    isBuildingHeightField: boolean,
    newFieldValue: number | string,
  ) =>
    (isBuildingHeightField && newFieldValue !== undefined);

  const buildingTypeHasChanged = (
    isBuildingTypeField: boolean,
    newFieldValue: number | string,
  ) =>
    (isBuildingTypeField && Number(newFieldValue) > -1);
  const shortestBuildingLengthHasChanged = (
    isShortestBuildingLengthField: boolean,
    newFieldValue: number | string,
  ) =>
    (isShortestBuildingLengthField && Number(newFieldValue) > -1);

  const longestBuildingLengthHasChanged = (
    isLongestBuildingLengthField: boolean,
    newFieldValue: number | string,
  ) => (
    isLongestBuildingLengthField && !!newFieldValue);

  function shouldRecalculateZonesNon716(
    field: string,
    newFieldValue: number | string,
    previousValues: projectEnvConfig,
    currentValues: projectEnvConfig,
    productId: number,
  ) {
    const isBuildingHeightField = field === buildingHeight.apiField;
    const isBuildingTypeField = field === residentalBuildingTypes.apiField;
    const isShortestBuildingLengthField = field === shortestBuildingLengthApiField;
    const isLongestBuildingLengthField = field === longestBuildingLengthApiField;
    if (!areRequiredFieldsSet({
      buildingHeightValue: currentValues.building_height,
      field,
      longestBuildingLengthValue: currentValues.building_length,
      newFieldValue,
      productId,
      shortestBuildingLengthValue: currentValues.shortest_building_length,
    })) {
      return false;
    }
    return (
      buildingHeightHasChanged(isBuildingHeightField, newFieldValue) ||
      buildingTypeHasChanged(isBuildingTypeField, newFieldValue) ||
      shortestBuildingLengthHasChanged(isShortestBuildingLengthField, newFieldValue) ||
      longestBuildingLengthHasChanged(isLongestBuildingLengthField, newFieldValue)
    );
  }
  const shouldRecalculatePanelZonesNon716 = shouldRecalculateZonesNon716(
    field,
    value,
    previousEnvConfig,
    currentEnvConfig,
    productId,);




  const shouldRecalculateAsce = shouldRecalculateAsce716(
    field,
    value,
    previousEnvConfig,
    currentEnvConfig,
    productId,
  );
  let shouldRecalculateNonAsce = shouldRecalculateNonAsce716or722(field, value);
  const isBuildingCodeHasChangedToAsce716 = buildingCodeHasChangedToAsce716or722(field === buildingCode.apiField, Number(value), previousEnvConfig.building_code, currentEnvConfig.building_height);

  const changeRoofPitch = () => {
    if (isRM5(productId) || isRM10orRM10Evo(productId)) {
      const roofs = getProjectRoofs();
      if (roofs) {
        Object.keys(roofs).forEach(roofKey => {
          const roofId = Number(roofKey);
          const roof: drawingManagerRoof = roofs[roofId];
          if (roof.roofPitch > 5) {
            dispatch(SET_ROOF_ROOF_PITCH(roofId, '0'));
          }
        });
      }
    }
  }


  const {
    wind_exposure: prev_wind_exposure, building_code: prev_building_code, risk_category: prev_risk_category,
    roof_type: prev_roof_type,
    material_thickness: prev_material_thickness, wind_speed: prev_wind_speed,
    limit_down_point_loads: prev_limit_down_point_loads,
  } = previousEnvConfig;
  const {
    wind_exposure: current_wind_exposure, building_code: current_building_code, risk_category: current_risk_category,
    dead_load_factor_modification: current_dead_load_factor_modification, soil_class: current_soil_class,
    topographical_factor_kzt: current_topographical_factor_kzt,
    wind_speed: current_wind_speed,
    snow_load: current_snow_load,
    tilt,
    roof_type: current_roof_type,
    material_thickness: current_material_thickness,
    block_weight: current_block_weight,
    is_half_block_allowed: current_is_half_block_allowed,
    half_block_weight: current_half_block_weight,
    building_length: current_building_length,
    shortest_building_length: current_shortest_building_length,
    building_height: current_building_height,
    preferred_span: current_preferred_span,
    rafter_spacing_inches: current_rafter_spacing_inches,
    attachment_type: current_attachment_type,
    roof_substrate: current_roof_substrate,
    setback_distance: current_setback_distance,
    rain_load: current_rain_load
  } = currentEnvConfig;
  let attachment_values;

  const ss_thickness_values = configStandingSeam.data.map(d => d.value);

  const rp_thickness_values = configRPanel.data.map(d => d.value);

  const cm_thickness_values = configCorrugated.data.map(d => d.value);


  if (isMetalX(productId)) {

    if (field === topographicalFactorKztApiField && value !== previousEnvConfig.topographical_factor_kzt) {
      if (value < topographicalFactorKzt.min || value > topographicalFactorKzt.max) {
        dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Topographical Factor must lie in between ${topographicalFactorKzt.min} and ${topographicalFactorKzt.max}`));
      }
      if (isASCE710(current_building_code)) {
        if (current_topographical_factor_kzt * Number(current_wind_speed) > 190) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
        }
      }
    }
    if ((field === metalXRoofTypeApiField && value !== prev_roof_type) || (field === mxMaterialThicknessApiField && value !== prev_material_thickness)) {
      const standingSeamSelected = current_roof_type === ROOF_TYPES.STANDING_SEAM;
      const rPanelSelected = current_roof_type === ROOF_TYPES.R_PANEL;
      const corrugatedSelected = current_roof_type === ROOF_TYPES.CORRUGATED_METAL;
      const roofTypeOptions = [standingSeamSelected, rPanelSelected, corrugatedSelected];
      attachment_values = getAttachmentTypeOptions(roofTypeOptions, current_material_thickness).map(i => i.value);
      if (attachment_values.length === 0) {
        if (current_roof_type === ROOF_TYPES.STANDING_SEAM && !ss_thickness_values.includes(current_material_thickness)) {
          dispatch(SET_PROJECT_OPTION(mxMaterialThicknessApiField, MATERIAL_THICKNESS.GA_22));
          dispatch(SET_PROJECT_OPTION(metalXAttachmentTypeField, ATTACHMENT_TYPES.STAND_2SS));
        } else if (current_roof_type === ROOF_TYPES.R_PANEL && !rp_thickness_values.includes(current_material_thickness)) {
          dispatch(SET_PROJECT_OPTION(mxMaterialThicknessApiField, MATERIAL_THICKNESS.GA_22));
          dispatch(SET_PROJECT_OPTION(metalXAttachmentTypeField, ATTACHMENT_TYPES.PM_ADJUST_SLOTTED));
        } else if (current_roof_type === ROOF_TYPES.CORRUGATED_METAL && !cm_thickness_values.includes(current_material_thickness)) {
          dispatch(SET_PROJECT_OPTION(mxMaterialThicknessApiField, MATERIAL_THICKNESS.GA_22));
          dispatch(SET_PROJECT_OPTION(metalXAttachmentTypeField, ATTACHMENT_TYPES.CORRUBRACKET));
        }
      } else {
        dispatch(SET_PROJECT_OPTION(metalXAttachmentTypeField, attachment_values[0]));
      }
    }
    if (field === windSpeedApiField) {
      if (isASCE710(current_building_code)) {
        if (current_topographical_factor_kzt * Number(current_wind_speed) > 190) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
        }
      }
    }
    if (field === buildingCodeApiField && value !== previousEnvConfig.building_code) {
      if (isASCE716(current_building_code)) {
        dispatch(CLEAR_INVALID_FIELD(topographicalFactorKztApiField));
      }
      if (isASCE710(current_building_code)) {
        if (current_topographical_factor_kzt * Number(current_wind_speed) > 190) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
        }
      }
    }
  }

  if (isCanadianChangesProduct(productId)) {
    if (field === rainLoadApiField) {
      if (current_rain_load < 0.1 || current_rain_load > 1) {
        dispatch(SET_FIELD_INVALID(rainLoadApiField, 'Rain Load (Sr) must lie between 0.1 and 1'))
      }
    }
  }

  if (isRMFamily(productId)) {
    if (field === buildingHeightApiField && value !== previousEnvConfig[buildingHeightApiField]) {
      if (isASCE705(current_building_code)) {
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
      } else if (!isCanadianZipcode) {
        const deadLoadFactor = (current_wind_exposure === 'D' || current_risk_category > 3) || current_wind_speed > 140 || current_building_height > 100 || isRmGridflex10(productId) ? 0.6 : null;
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor||current_dead_load_factor_modification));
      }
    }

    if (field === windExposureApiField &&
      current_wind_exposure !== prev_wind_exposure) {
      if (isASCE705(current_building_code)) {
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
      } else if (!isCanadianZipcode) {
        const deadLoadFactor = current_wind_exposure === 'D' || current_risk_category > 3 || current_wind_speed > 140 || current_building_height > 100 || isRmGridflex10(productId) ? 0.6 : null;
        if (current_dead_load_factor_modification !== deadLoadFactor) dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor||current_dead_load_factor_modification));
      }
    }


    if (field === buildingCodeApiField && current_building_code !== prev_building_code) {
      if (isASCE705(current_building_code)) {
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
      } else if (!isCanadianZipcode) {
        const deadLoadFactor = (current_wind_exposure === 'D' || current_risk_category > 3) || current_wind_speed > 140 || current_building_height > 100 || isRmGridflex10(productId) ? 0.6 : null;
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor||current_dead_load_factor_modification));
      }
    }

    if (field === windSpeedApiField && current_wind_speed !== prev_wind_speed) {
      if (isASCE705(current_building_code)) {
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
      } else if (!isCanadianZipcode) {
        const deadLoadFactor = current_wind_exposure === 'D' || current_risk_category > 3 || current_wind_speed > 140 || current_building_height > 100 || isRmGridflex10(productId) ? 0.6 : null;
        if (current_dead_load_factor_modification !== deadLoadFactor) {
          dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor||current_dead_load_factor_modification));
        }
      }
    }

    if (field === riskCategory.apiField && current_risk_category !== prev_risk_category) {
      if (isASCE705(current_building_code)) {
        dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
      } else if (!isCanadianZipcode) {
        const deadLoadFactor = current_wind_exposure === 'D' || current_risk_category > 3 || current_wind_speed > 140 || current_building_height > 100 || isRmGridflex10(productId) ? 0.6 : null;
        if (current_dead_load_factor_modification !== deadLoadFactor) dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor||current_dead_load_factor_modification));
      }
    }

    if (field === buildingHeightApiField && (value !== previousEnvConfig.building_height || value === 0)) {
      const { lowerLimit, upperLimit } = getBuildingHeightLimits();
      if (isRM10orRM10Evo(productId) || isRM5(productId) || isRMGridFlex(productId) || isRMDT(productId) || isEcoFoot2Plus(productId)) {
        if (value < lowerLimit || value > upperLimit) {
          dispatch(SET_FIELD_INVALID(buildingHeightApiField, `Building height must lie between ${lowerLimit} and ${upperLimit}.`));
        }
        if(isASCE716or722(previousEnvConfig.building_code) && buildingHeightCheck(value, inputUnit)){
          dispatch(SET_PROJECT_OPTION(buildingStoreyApiField, previousEnvConfig.building_height_greater_than_6_storey));
        }
      } else if (value < lowerLimit || value > upperLimit) {
        dispatch(SET_FIELD_INVALID(buildingHeightApiField, `Building height must lie between ${lowerLimit} and ${upperLimit}.`));
      }
    }

    if (isRM10Evolution(productId) || isRmGridflex10(productId)) {
      if (field === rmIFIParapetHeightApiField && value !== previousEnvConfig.parapet_height_input) {
        if (isMetricUnit(inputUnit)) {
          if (value < rmIFIParapetHeightMetric.min || value > rmIFIParapetHeightMetric.max) {
            dispatch(SET_FIELD_INVALID(
              rmIFIParapetHeightApiField,
              `Parapet Height must lie between ${rmIFIParapetHeightMetric.min} and ${rmIFIParapetHeightMetric.max} meters`));
          }
        }
        else {
          if (value < rmIFIParapetHeight.min || value > rmIFIParapetHeight.max) {
            dispatch(SET_FIELD_INVALID(
              rmIFIParapetHeight.apiField,
              `Parapet Height must lie between ${rmIFIParapetHeight.min} and ${rmIFIParapetHeight.max} inches`));
          }
        }

      }
    }

    if (isRM10Evolution(productId) && field === "enable_cpp") {
      const deadLoadFactor = value ? 0.6 : 1;
      if (current_dead_load_factor_modification !== deadLoadFactor) dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, deadLoadFactor));
    }

    if (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || isRMGridFlex(productId) || isRM5(productId) || isRMDT(productId) || isRmGridflex10(productId)) {
      const { projectConfiguration: { projectVersion } } = state();
      if (field === deadLoadFactorApiField && !isCanadianZipcode) {
        if (isRmGridflex10(productId)){
          if ((current_wind_exposure === 'D' || current_risk_category > 2 || current_wind_speed > 140 || current_building_height > 100) && (value < 0.001 || value > 0.6)) {
            dispatch(SET_FIELD_INVALID(deadLoadFactorApiField, 'Please enter the dead load factor ranging from  0.001 to 0.6'));
          } else if (value < 0.001 || value > 0.75) {
            dispatch(SET_FIELD_INVALID(deadLoadFactorApiField, 'Please enter the dead load factor ranging from  0.001 to 0.75'));
          }
        }
        else{
          if ((isASCE705(current_building_code) || current_wind_exposure === 'D' || current_risk_category > 3 || current_wind_speed > 140 || current_building_height > 100) && (value < 0.001 || value > 0.6) && applyDeadloadFactorLimitChanges(projectVersion)) {
            if(value > 0.6){
              dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6));
            }
            else if(value < 0.001){
              dispatch(SET_FIELD_INVALID(deadLoadFactorApiField, 'Please enter the dead load factor ranging from  0.001 to 0.6'));
            }
          } else {
            if(value > 1){
              dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 1));
            }
            else if(value < 0.001){
              dispatch(SET_FIELD_INVALID(deadLoadFactorApiField, 'Please enter the dead load factor ranging from  0.001 to 1'));
            }
          }
        }
      }

      if (field === topographicalFactorKztApiField && value !== previousEnvConfig.topographical_factor_kzt) {
        if (value < topographicalFactorKzt.min) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Topographical Factor must be greater than or equal to ${topographicalFactorKzt.min}`));
        }
        if (value > topographicalFactorKzt.max) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Topographical Factor must be less than or equal to ${topographicalFactorKzt.max}`));
        }
      }
      if (field === buildingCodeApiField && current_building_code !== prev_building_code) {
        if (isASCE710(current_building_code)) {
          if (current_soil_class === SOIL_CLASS.D_DEFAULT) {
            dispatch(SET_PROJECT_OPTION(soilClassApiField, SOIL_CLASS.D));
          }
        }
      }
      if (field === rmIFIParapetHeight.apiField && value !== previousEnvConfig.parapet_height_input) {
        if (value < rmIFIParapetHeight.min || value > rmIFIParapetHeight.max) {
          dispatch(SET_FIELD_INVALID(
            rmIFIParapetHeightApiField,
            `Parapet Height must lie between ${rmIFIParapetHeight.min} and ${rmIFIParapetHeight.max} inches`));
        }
      }
      showTornadoSpeedWarning();
    }
    if (isRMIFIProduct(productId) || isRM5(productId) || isRM10(productId) || isRM10Evolution(productId) || isRmGridflex10(productId)) {
      const units = isMetricUnit(inputUnit) ? 'kg' : 'lbs';
      if (field === allowMechanicalAttachmentsApiField && value !== previousEnvConfig.allow_mechanical_attachments) {
        if (value === 1) {
          dispatch(SET_PROJECT_OPTION(attachmentOptimizationCriteriaApiField, AttachmentOptimization.MAXIMIZE_BALLAST));
          dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.UNIRAC_FLASHLOC_RM));
          changeRoofPitch();
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForFlashloc(productId, inputUnit).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForFlashloc(productId, inputUnit).default));
        } else {
          dispatch(SET_PROJECT_OPTION(attachmentOptimizationCriteriaApiField, null));
          dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, null));
          dispatch(SET_PROJECT_OPTION(roofPsfLimitApiField, null));
          dispatch(SET_PROJECT_OPTION(totalWeightOnRoofLimitApiField, null));
          dispatch(SET_PROJECT_OPTION(allowManualAttachmentsApiField, 0));
          (isRMGridFlex(productId) || isRmGridflex10(productId)) && dispatch(SET_PROJECT_OPTION(allowManualAttachmentsApiField,getYesOrNo()[1].value));
        }
      } else if (field === attachmentOptimizationCriteriaApiField && value !== previousEnvConfig.attachment_optimization_criteria) {
        if (value !== AttachmentOptimization.PSF_LIMIT) {
          dispatch(SET_PROJECT_OPTION(roofPsfLimitApiField, null));
        }
        if (value !== AttachmentOptimization.TOTAL_WEIGHT_ON_THE_ROOF) {
          dispatch(SET_PROJECT_OPTION(totalWeightOnRoofLimitApiField, null));
        }
      } else if (field === roofPsfLimitApiField && value !== previousEnvConfig.roof_psf_limit) {
        if (value < roofPsfLimit.min || value > roofPsfLimit.max) {
          dispatch(SET_FIELD_INVALID(
            roofPsfLimitApiField,
            `Roof PSF limit must lie between ${roofPsfLimit.min.toFixed(2)} and ${roofPsfLimit.max.toFixed(2)} PSF`));
        }
      } else if (field === totalWeightOnRoofLimitApiField && value !== previousEnvConfig.total_weight_on_roof_limit) {
        if (value < totalWeightonRoofLimit.min || value > totalWeightonRoofLimit.max) {
          dispatch(SET_FIELD_INVALID(
            totalWeightOnRoofLimitApiField,
            `Total Weight on Roof must lie between ${totalWeightonRoofLimit.min} and ${totalWeightonRoofLimit.max} lbs`));
        }
      } else if (field === attachmentTypeApiField && value !== previousEnvConfig.attachment_type) {
        if (value === AttachmentType.U_ANCHOR_U2400) {
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).default));
        } else if (value === AttachmentType.UNIRAC_FLASHLOC_RM) {
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForFlashloc(productId, inputUnit).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForFlashloc(productId, inputUnit).default));
          changeRoofPitch();
          dispatch(SET_FIELD_WARNING(field, 'Note: Max roof slope for Flasloc RM is 5 degrees'));
        } else if (value === AttachmentType.CUSTOM) {
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForCustom(productId, inputUnit).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForCustom(productId, inputUnit).default));
        } else if (value === AttachmentType.OMG) {
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForOMG(productId, inputUnit).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForOMG(productId, inputUnit).default));
        } else if(isMetricUnit(inputUnit)){
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowabledefaultValueKg));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowabledefaultValueKg));
        } else {
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowabledefaultValue));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowabledefaultValue));
        }
        if (value !== AttachmentType.UNIRAC_FLASHLOC_RM) {
          dispatch(CLEAR_WARNING(field));
        }
      } else if (field === anchorTypeApiField){
          dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, upliftAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion).default));
          dispatch(SET_PROJECT_OPTION(shearAllowableApiField, shearAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion).default));
      } else if (field === upliftAllowableApiField) {
        if (currentEnvConfig.attachment_type === AttachmentType.CUSTOM && (value < upliftAllowableForCustom(productId, inputUnit).min || value > upliftAllowableForCustom(productId, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            upliftAllowableApiField,
            `Uplift Allowable must lie between ${upliftAllowableForCustom(productId, inputUnit).min} and ${upliftAllowableForCustom(productId, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM && (value < upliftAllowableForFlashloc(productId, inputUnit).min || value > upliftAllowableForFlashloc(productId, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            upliftAllowableApiField,
            `Uplift Allowable must lie between ${upliftAllowableForFlashloc(productId, inputUnit).min} and ${upliftAllowableForFlashloc(productId, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.U_ANCHOR_U2400 && (value < upliftAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).min || value > upliftAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            upliftAllowableApiField,
            `Uplift Allowable must lie between ${upliftAllowableForUAnchor(productId, 1, projectVersion, inputUnit).min} and ${upliftAllowableForUAnchor(productId, 1, projectVersion, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.OMG && (value < upliftAllowableForOMG(productId, inputUnit).min || value > upliftAllowableForOMG(inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            upliftAllowableApiField,
            `Uplift Allowable must lie between ${upliftAllowableForOMG(productId, inputUnit).min} and ${upliftAllowableForOMG(productId, inputUnit).max} ${units}`));
        }
      } else if (field === shearAllowableApiField) {
        if (currentEnvConfig.attachment_type === AttachmentType.CUSTOM && (value < shearAllowableForCustom(productId, inputUnit).min || value > shearAllowableForCustom(productId, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            shearAllowableApiField,
            `Shear Allowable must lie between ${shearAllowableForCustom(productId, inputUnit).min} and ${shearAllowableForCustom(productId, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM && (value < shearAllowableForFlashloc(productId, inputUnit).min || value > shearAllowableForFlashloc(productId, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            shearAllowableApiField,
            `Shear Allowable must lie between ${shearAllowableForFlashloc(productId, inputUnit).min} and ${shearAllowableForFlashloc(productId, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.U_ANCHOR_U2400 && (value < shearAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).min || value > shearAllowableForUAnchor(productId, currentEnvConfig.anchor_type, projectVersion, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            shearAllowableApiField,
            `Shear Allowable must lie between ${shearAllowableForUAnchor(productId, 1, projectVersion, inputUnit).min} and ${shearAllowableForUAnchor(productId, 1, projectVersion, inputUnit).max} ${units}`));
        } else if (currentEnvConfig.attachment_type === AttachmentType.OMG && (value < shearAllowableForOMG(productId, inputUnit).min || value > shearAllowableForOMG(productId, inputUnit).max)) {
          dispatch(SET_FIELD_INVALID(
            shearAllowableApiField,
            `Shear Allowable must lie between ${shearAllowableForOMG(productId, inputUnit).min} and ${shearAllowableForOMG(productId, inputUnit).max} ${units}`));
        }
      } else if (field === ballastBlockHalfWeightApiField && value !== previousEnvConfig.half_block_weight) {
        const ballastBlockHalfWeight = ballastBlockHalfWeightField(inputUnit);
        if (value < ballastBlockHalfWeight.min || value > ballastBlockHalfWeight.max) {
          dispatch(SET_FIELD_INVALID(
            ballastBlockHalfWeightApiField,
            `Half block weight must lie between ${ballastBlockHalfWeight.min} and ${ballastBlockHalfWeight.max} ${units}`));
        }
        else if (value >= current_block_weight) {
          dispatch(SET_FIELD_INVALID(
            ballastBlockHalfWeightApiField,
            `Half block weight must be less than full block weight (${current_block_weight} ${units})`));
        }
      }
    }
    if (isRM10Evolution(productId) || isRmGridflex10(productId)) {
      if (field == parapetHeightInputApiField && value == null) {
        dispatch(SET_PROJECT_OPTION(parapetHeightInputApiField, 0));
      }
    }
  }

  if (isEcoFoot2Plus(productId)) {
    if (field === windSpeedApiField && !isCanadianZipcode) {
      if (value < 85 || value > 180) {
        dispatch(SET_FIELD_INVALID(windSpeedApiField, `Wind speed must lie between 85 and 180.`));
      }
    }
  }

  if(isEcoFoot2Plus(productId) || isRM10orRM10Evo(productId) || isRM5(productId) || isRMIFIProduct(productId) || isRmGridflex10(productId)) {
    const { lowerLimit, upperLimit } =  getSetbackLimits();

    if (field === buildingHeightApiField || field === setbackDistanceApiField) {
      if (lowerLimit > current_setback_distance || current_setback_distance > upperLimit) {
        dispatch(SET_FIELD_INVALID(setbackDistanceApiField, `Setback distance must lie between ${lowerLimit} and ${upperLimit}`));
      } else {
        if (isMetricUnit(inputUnit) ? current_setback_distance >= metersToCms(current_building_height) / 2 : current_setback_distance >= current_building_height / 2) {
          dispatch(SET_FIELD_INVALID(setbackDistanceApiField, `Setback distance must be less than 1/2*building height (${isMetricUnit(inputUnit) ? `${round(metersToCms(current_building_height) / 2, 1)} cm` : `${current_building_height / 2} ft`})`));
        } else {
          dispatch(CLEAR_INVALID_FIELD(setbackDistanceApiField));
        }
      }
    }
    if (field === setbackDistanceApiField && currentEnvConfig.building_code === previousEnvConfig.building_code) {
      shouldRecalculateNonAsce = true;
    }
  }


  if (isEcoFoot2Plus(productId) || isRMFamily(productId)) {
    const maxDownPointLoadToBeAllowed = maxDownPointLoadToBeAllowedField(inputUnit);
    if (field === limitDownPointLoadsApiField && value != prev_limit_down_point_loads) {
      dispatch(SET_PROJECT_OPTION(maxDownPointLoadToBeAllowedApiField, undefined));
    }
    else if (field === maxDownPointLoadToBeAllowedApiField && value !== previousEnvConfig.max_down_point_load_to_be_allowed) {
      if (value < maxDownPointLoadToBeAllowed.min || value > maxDownPointLoadToBeAllowed.max) {
        dispatch(SET_FIELD_INVALID(
          maxDownPointLoadToBeAllowedApiField,
          `Max Point load must lie between ${maxDownPointLoadToBeAllowed.min} and ${maxDownPointLoadToBeAllowed.max}`));
      }
    }
  }


  let useNewZoneClassification = needNewZoneClassification(projectVersion);
  if (!isASCE716or722Selected() && shouldRecalculatePanelZonesNon716 && !isRMIFIProduct(productId) && useNewZoneClassification) {
    updateNon716Panels(productId);
  } else if (shouldRecalculateAsce && !isRMIFIProduct(productId)) {
    switchProjectToASCE716(productId, isBuildingCodeHasChangedToAsce716);
  } else if (shouldRecalculateNonAsce) {
    switchProjectToNonASCE716(productId);
  }

  if (field === buildingCode.apiField) {
    setProperRoofPitchForResidentialsASCE716(value, currentEnvConfig.building_type);
  }

  if (field === clampsChoice.apiField) {
    if (value === 0) {
      dispatch(SET_ROW_SPACING(inchesToMeters(1)));
    }

    if (value === 1) {
      dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
    }
  }

  if (field === buildingCode.apiField || field === riskCategoryApiField) {
    const currentBuildingCode = field === buildingCode.apiField ? value : currentEnvConfig.building_code;
    const risk_category = field === riskCategoryApiField ? value : currentEnvConfig.risk_category;

    dispatch(CLEAR_ENV_FACTORS_FOR());

    if (zipcode && zipcode.length && !(isASCE722(currentBuildingCode))) {
      updateEnviromentalFactors(zipcode, currentBuildingCode, productId, risk_category);
    }
    if ((isASCE716or722(currentBuildingCode) && (isGFT(productId))) || isRMAndEcofootFamily(productId) || isRMIFIProduct(productId)) {
      enableObstructionsZoneView();
    } else {
      disableObstructionZoneView();
      disableWindZoneView();
    }

    if ( prev_building_code != current_building_code && isASCE722(current_building_code)){
      let envFactorsApiFields = [snowLoadApiField, seismicSsApiField, seismicS1ApiField, seismicSds.apiField, seismicSd1.apiField, deadLoadFactorApiField]
      envFactorsApiFields = isCanadianChangesProduct(productId) && isCanadianZipcode ? envFactorsApiFields : [...envFactorsApiFields, riskCategoryApiField, windSpeedApiField ];
      envFactorsApiFields.forEach((field)=>(dispatch(SET_PROJECT_OPTION(field, ''))))
      dispatch(SET_PROJECT_OPTION(elevation.apiField, ''))
      dispatch(SET_PROJECT_OPTION(tornadoSpeed.apiField, 0))
      dispatch(SET_PROJECT_OPTION(deadLoadFactorApiField, 0.6))

    }
  }
  
  if (field === snowLoadApiField) {
    onSnowLoadChanged(value, productId, currentEnvConfig);
  }

  if (field === staggerAttachments.apiField) {
    onStaggerAttachmentsChanged(value, productId, currentEnvConfig);
  }

  if (isULA(productId)) {

    const { rail_type, clamp_type, } = currentEnvConfig;
    const { rail_type: prev_rail_type } = previousEnvConfig

    if (field === ulaClampsType.apiField) {
      if ((value === "11") || (value === "13")) {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if ((value === "01") || (value === "02") || (value === "03")) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      } else if (value === '04') {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      }
    }

    if (field === clampLocation.apiField) {
      if (clamp_type === PRO_SERIES && (value === ULAClampLocationType.TOP_CLAMPS || value === ULAClampLocationType.BOTH_TOP_AND_BOTTOM_CLAMPS)) {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if ((clamp_type === STANDARD && (value === ULAClampLocationType.TOP_CLAMPS || value === ULAClampLocationType.BOTH_TOP_AND_BOTTOM_CLAMPS)) || value === ULAClampLocationType.BOTTOM_CLAMPS) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      }

    }

    if (field === railTypeApiField && value !== prev_rail_type) {
      if (rail_type === ULARailType.SM_HD && value !== prev_rail_type) {
        dispatch(SET_PROJECT_OPTION(clampLocation.apiField, ULAClampLocationType.TOP_CLAMPS));
      }
    }

    if (field === frontEdgeHeightApiField) {
      if (value < 1.5 || value > 5) {
        dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between 1.5 and 5`));
      }
    }

    if (field === foundationTypeULAApiField) {
      const { foundation_type: prev_foundation_type_value } = previousEnvConfig;
      if (value === prev_foundation_type_value) return;
      if (value === foundationTypeULAOptions.aboveGradeBallast.value || value === foundationTypeULAOptions.groundScrew.value) {
        dispatch(SET_PROJECT_OPTION(foundationDiameterULAApiField, null)); // clear out foundation Diameter field if Ballast or groundscrew Foundation is selected
      } else if (value === foundationTypeULAOptions.concrete.value) {
        dispatch(SET_PROJECT_OPTION(foundationDiameterULAApiField, 18)); // set the default foundation Diameter value to 18 if Concrete Foundation is selected
      }
    }

    if (field === foundationTypeULAApiField) {
      const { foundation_screw_length: prev_foundation_screw_length } = previousEnvConfig;
      if (value === prev_foundation_screw_length) return;
      if (value === foundationTypeULAOptions.aboveGradeBallast.value || value === foundationTypeULAOptions.concrete.value) {
        dispatch(SET_PROJECT_OPTION(foundationScrewLengthApiField, null)); // clear out foundation Screw length field if ballast and concrete Foundation is selected
      } else if (value === foundationTypeULAOptions.groundScrew.value) {
        dispatch(SET_PROJECT_OPTION(foundationScrewLengthApiField, 1600)); // set the default foundation Screw length value to 1600 if ground Screw Foundation is selected
        if (applyKrinnerGroundScrewULA(projectVersion)) {
          dispatch(SET_PROJECT_OPTION(groundScrewManufacturerApiField, 1));
        }
      }
    }

    showTornadoSpeedWarning();
  }

  if (isGFT(productId)) {

    if (field === 'clamp_type') {
      if (value === '01') {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
      }

      if (value === '11') {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
      }

      if (value === '04') {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
      }
    }

    if (field === tiltApiField) {
      const { tilt: prev_tilt_value } = previousEnvConfig;
      if (prev_tilt_value === value) { // dont update frontEdgeHeight field if same tilt value has been reselected
        return;
      }
      const _default = get(frontEdgeHeight, ['data', value, 'default']);
      if (_default) {
        dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, _default));
      }
    }

    if (field === frostDepthApiField) {
      const two_decimal_validator = (value) => {
        return value.toFixed(2).replace(/\.?0*$/, '');
      };

      const two_decimal_value_input = two_decimal_validator(value);
      dispatch(SET_PROJECT_OPTION(frostDepthApiField, two_decimal_value_input));
    }

    if (field === frontEdgeHeightApiField) {
      const { tilt } = currentEnvConfig;
      const { frost_depth, foundation_type, front_edge_height } = currentEnvConfig;

      if (!frontEdgeHeight.data[tilt]) return;
      const { min, max } = frontEdgeHeight.data[tilt];
      const minFrontedgeHeightForFrostDepthGftForTilt_20 = 2;
      const maxFrontedgeHeightForFrostDepthGftForTilt_20 = 4.5;
      const minFrontedgeHeightForFrostDepthGftForTilt_30 = 2.5;
      const maxFrontedgeHeightForFrostDepthGftForTilt_30 = 3;

      if (frost_depth <= 3.5) {
        if (value < min || value > max) {
          dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between ${min} and ${max} feet for ${tilt}\u00B0 tilt`));
        }
      } else if (frost_depth > 3.5 && foundation_type === 2) {
        if (tilt === 20) {
          if (value < minFrontedgeHeightForFrostDepthGftForTilt_20 || value > maxFrontedgeHeightForFrostDepthGftForTilt_20) {
            dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between ${minFrontedgeHeightForFrostDepthGftForTilt_20} and ${maxFrontedgeHeightForFrostDepthGftForTilt_20} feet for ${tilt}\u00B0 tilt`));

          } else if (front_edge_height < minFrontedgeHeightForFrostDepthGftForTilt_20 || front_edge_height > maxFrontedgeHeightForFrostDepthGftForTilt_20) {
            dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between ${minFrontedgeHeightForFrostDepthGftForTilt_20} and ${maxFrontedgeHeightForFrostDepthGftForTilt_20} feet for ${tilt}\u00B0 tilt`));

          }

        } else if (tilt === 30) {
          if (value < minFrontedgeHeightForFrostDepthGftForTilt_30 || value > maxFrontedgeHeightForFrostDepthGftForTilt_30) {
            dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between ${minFrontedgeHeightForFrostDepthGftForTilt_30} and ${maxFrontedgeHeightForFrostDepthGftForTilt_30} feet for ${tilt}\u00B0 tilt`));

          }
        }
      } else if (foundation_type === 1) {
        if (value < min || value > max) {
          dispatch(SET_FIELD_INVALID(frontEdgeHeightApiField, `Front edge height must lie between ${min} and ${max} feet for ${tilt}\u00B0 tilt`));
        }
      }
    }

    if (field === iceThicknessApiField) {
      if (value < 0 || value > 2.5) {
        dispatch(SET_FIELD_INVALID(iceThicknessApiField, 'Ice Thickness must lie between 0 and 2.5 in'));
      }
    }

    if (field === windOnIceApiField) {
      if (value < 0 || value > 80) {
        dispatch(SET_FIELD_INVALID(windOnIceApiField, 'Wind on Ice must lie between 0 and 80 mph'));
      }
    }

    if (field === foundationTypeApiField) {
      const { foundation_type: prev_foundation_type_value } = previousEnvConfig;
      if (value === prev_foundation_type_value) return;
      if (value === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationDiameterApiField, null)); // clear out foundation Diameter field if Driven Foundation is selected
      } else if (value === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationDiameterApiField, 2)); // set the default foundation Diameter value to 2 if CastInPlace Foundation is selected
      }
    }

    if (field === foundationTypeApiField || field === frontEdgeHeightApiField || field === tiltApiField) {
      const { foundation_type, front_edge_height, tilt, foundation_length, frost_depth } = currentEnvConfig;
      const defaultFoundationLength = getDefaultFoundationLength(foundation_type, tilt, front_edge_height, frost_depth)._default;
      if (foundation_type === foundationTypeOptions.driven.value
        && defaultFoundationLength === FOUNDATION_LENGTH.PILE_15
        && foundation_length !== FOUNDATION_LENGTH.PILE_15) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
      } else if (tilt === 20 && frost_depth > 3.5 && front_edge_height === 2 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_12_5));

      } else if (tilt === 20 && frost_depth > 3.5 && front_edge_height > 2 && front_edge_height <= 4.5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 30 && frost_depth > 3.5 && front_edge_height >= 2.5 && front_edge_height <= 3 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 20 && frost_depth > 3.5 && front_edge_height > 4.5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 4.5));
      } else if (tilt === 30 && frost_depth > 3.5 && front_edge_height > 3 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 3));
      } else if (tilt === 20 && front_edge_height > 6.5 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 6.5));
      } else if (tilt === 30 && front_edge_height > 5 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 5));
      } else if (tilt === 20 && frost_depth <= 3.5 && front_edge_height > 6.5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 6.5));
      } else if (tilt === 30 && frost_depth <= 3.5 && front_edge_height > 5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));
        dispatch(SET_PROJECT_OPTION(frontEdgeHeightApiField, 5));
      } else if (tilt === 20 && frost_depth <= 3.5 && front_edge_height === 2 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_10_5));

      } else if (tilt === 20 && frost_depth <= 3.5 && front_edge_height > 2 && front_edge_height <= 4 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_12_5));

      } else if (tilt === 20 && frost_depth <= 3.5 && front_edge_height > 4 && front_edge_height <= 6.5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 30 && frost_depth <= 3.5 && front_edge_height === 2.5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_12_5));

      } else if (tilt === 30 && frost_depth <= 3.5 && front_edge_height > 2.5 && front_edge_height <= 5 && foundation_type === foundationTypeOptions.castInPlace.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 20 && front_edge_height >= 2 && front_edge_height <= 3 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_12_5));

      } else if (tilt === 20 && front_edge_height > 3 && front_edge_height <= 6.5 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 30 && front_edge_height === 2.5 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      } else if (tilt === 30 && front_edge_height > 2.5 && front_edge_height <= 5 && foundation_type === foundationTypeOptions.driven.value) {
        dispatch(SET_PROJECT_OPTION(foundationLengthApiField, FOUNDATION_LENGTH.PILE_15));

      }
    }

    if(field===topChordApiField && ((currentEnvConfig.top_chord === topChordOptions.old_top_chord.value && rail_arrangement_type === railArrangementTypeOptions._8rail.value) || (currentEnvConfig.top_chord === topChordOptions.new_top_chord.value && rail_arrangement_type === railArrangementTypeOptions._5rail.value)))
      dispatch(SET_PROJECT_OPTION(railArrangementTypeApiField, railArrangementTypeOptions._6rail.value));
    showTornadoSpeedWarning();
  }

  if ((isSolarMount(productId) && tilt === null) || isNxtHorizon(productId) || isSMTiltPR(productId)) {
    const { roof_type: prev_roof_type, tile_replacement_or_solarhooks: prev_tile_replacement_or_solarhooks, attachment_type: prev_attachment_type } = previousEnvConfig;
    const { roof_type, tile_replacement_or_solarhooks, attachment_type, roof_substrate, preferred_span, rail_direction } = currentEnvConfig;
    if (field === smRooftypeApiField && value !== prev_roof_type) {
      if (roof_type === RoofType.TILE) {
        dispatch(SET_PROJECT_OPTION(solarhooksOrTileReplacementApiField, TileReplacementOrSolarhooks.TILE_REPLACEMENT));
        dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, null));
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, null));
      }
      if (roof_type === RoofType.SHINGLE || roof_type === RoofType.OTHERS) {
        dispatch(SET_PROJECT_OPTION(solarhooksOrTileReplacementApiField, null));
      }
      if (roof_type === RoofType.STANDING_SEAM) {
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, RoofMaterialThickness.GA_22));
        dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.MINI_1SS));
        dispatch(SET_PROJECT_OPTION(solarhooksOrTileReplacementApiField, null));
        if(isNxtHorizon(productId)){
          dispatch(SET_PROJECT_OPTION(railClampOptionApiField, NXTRailClampOption.RAIL_CLAMP_ONLY));
        }
      }
      if (roof_type === RoofType.R_PANEL) {
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, RoofMaterialThickness.GA_24));
        dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.PM_9000S));
        dispatch(SET_PROJECT_OPTION(solarhooksOrTileReplacementApiField, null));
      }
      if ([RoofType.SHINGLE, RoofType.OTHERS].includes(roof_type)) {
        if (isNxtHorizon(productId)) {
          dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.STRONGHOLD_ATT_KIT_COMP));
        }
        else {
          dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.FLASHKIT_PRO));
        }
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, null));
        dispatch(SET_PROJECT_OPTION(solarhooksOrTileReplacementApiField, null));
      }
      if ([RoofType.TILE, RoofType.STANDING_SEAM, RoofType.SHINGLE, RoofType.R_PANEL,
      RoofType.OTHERS].includes(roof_type)) {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));

        const { projectConfiguration: { projectEnvConfig: { preferred_span: latest_preferred_span } } } = state();
        validateOnPreferredSpan(latest_preferred_span, 24);
      }
    }
    showTornadoSpeedWarning();

    if (field === solarhooksOrTileReplacementApiField && roof_type === RoofType.TILE && value !== prev_roof_type && value !== prev_tile_replacement_or_solarhooks) {
      if (tile_replacement_or_solarhooks === TileReplacementOrSolarhooks.SOLARHOOKS) {
        dispatch(SET_PROJECT_OPTION(solarhookapifield, AttachmentType.SOLARHOOK_FLAT_TILE));
      }
    }

    if (field === attachmentTypeApiField && roof_type === RoofType.R_PANEL && value !== prev_attachment_type) {
      if (attachment_type === AttachmentType.PM_9000S) {
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, RoofMaterialThickness.GA_24));
      }
      if (attachment_type === AttachmentType.PM_ADJUST) {
        dispatch(SET_PROJECT_OPTION(materialThicknessApiField, RoofMaterialThickness.GA_22));
      }
    }
    if (field === attachmentTypeApiField && value !== prev_attachment_type) {
      if(roof_type === RoofType.SHINGLE){
      if (attachment_type === AttachmentType.FLASHLOC_DUO || attachment_type === AttachmentType.STRONGHOLD_ATT_BUTYL || attachment_type === AttachmentType.SM_BUTYL_DTD) {
        dispatch(SET_PROJECT_OPTION(roofSubstrateApiField, [AttachmentType.SM_BUTYL_DTD, AttachmentType.STRONGHOLD_ATT_BUTYL, AttachmentType.FLASHLOC_DUO].includes(attachment_type) ? null : RoofSubstrate.OSB));
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 1));
        validateOnPreferredSpan(preferred_span, 1);
      }
      if ([AttachmentType.L_FOOT_ONLY, AttachmentType.FLASHKIT_PRO, AttachmentType.FLASHLOC_COMP_KIT,
      AttachmentType.FLASHKIT_PRO_SB].includes(attachment_type)) {
        dispatch(SET_PROJECT_OPTION(roofSubstrateApiField, RoofSubstrate.OSB));
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));
        validateOnPreferredSpan(preferred_span, 24);
      }
      }

      if (attachment_type === AttachmentType.FLASHLOC_DUO && removeUpslopeRail(value, projectVersion) && rail_direction === "NS") {
        dispatch(SET_PROJECT_OPTION(railDirectionApiField, "EW"));
      }
    }
    if (field === roofSubstrateApiField) {
      if (isOsbOrPlywood(roof_type, attachment_type, roof_substrate)) {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 1));
        validateOnPreferredSpan(preferred_span, 1);
      } else {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));
        validateOnPreferredSpan(preferred_span, 24);
      }
    }

    if (field === midClampsApiField && !isSMTiltPR(productId)) {
      if (value === MidEndClampChoice.PRO_CLAMP) {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if (value === MidEndClampChoice.STANDARD_CLAMP) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      } else if (value === MidEndClampChoice.UNIVERSAL_CLAMP) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      } else if (value === MidEndClampChoice.NXT_HORIZON_COMBO_CLAMP) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      }
    }
  }

  if (isSfmInfinity(productId) || (isSolarMount(productId) && tilt === null) || isNxtHorizon(productId) || isSMTiltPR(productId) || (adjustableTiltSystem(projectVersion) && (isSMTilt(productId) || isNxtTilt(productId)))) {
    if (field === topographicalFactorKztApiField) {
      if ((value < topographicalFactorKzt.min || value > topographicalFactorKzt.max)) {
        dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Topographical Factor must lie in between ${topographicalFactorKzt.min} and ${topographicalFactorKzt.max}`));
      }
      if ((isASCE710(current_building_code) || isASCE705(current_building_code)) && current_topographical_factor_kzt * Number(current_wind_speed) > (isMetricUnit(inputUnit) ? 305.775 : 190)) {
        dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
      }
    }
    if (field === windSpeedApiField) {
      if ((isASCE710(current_building_code) || isASCE705(current_building_code)) && current_topographical_factor_kzt * Number(current_wind_speed) > 190) {
        dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
      }
    }
    if (field === buildingCodeApiField && value !== previousEnvConfig.building_code) {
      if (isASCE716or722(current_building_code)) {
        if (current_topographical_factor_kzt < topographicalFactorKzt.min || current_topographical_factor_kzt > topographicalFactorKzt.max) {
          dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Topographical Factor must lie in between ${topographicalFactorKzt.min} and ${topographicalFactorKzt.max}`));
        } else {
          dispatch(CLEAR_INVALID_FIELD(topographicalFactorKztApiField));
        }
      }
      if ((isASCE710(current_building_code) || isASCE705(current_building_code)) && current_topographical_factor_kzt * Number(current_wind_speed) > (isMetricUnit(inputUnit) ? 305.775 : 190)) {
        dispatch(SET_FIELD_INVALID(topographicalFactorKztApiField, `Please contact Unirac for projects with severe topographical conditions.`));
      }
    }
  }

  if (isSFMInfinity(productId)) {
    const { attachment_type, roof_substrate } = currentEnvConfig;
    if (field === roofTypeApiField) {
      if (value === RoofType.SHINGLE) {
        if (attachment_type === null) {
          dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.FLASHKIT));
        }
        else if (attachment_type === AttachmentType.SFM_BUTYL_DTD) {
          dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 1));
        }
      }
      else {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));
      }
    }
    if (field === attachmentTypeApiField){
      if(current_roof_type === RoofType.SHINGLE && value === AttachmentType.SFM_BUTYL_DTD){
        dispatch(SET_PROJECT_OPTION(roofSubstrateApiField, null));
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 1));  
      }
      else {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));
      }
    }

    if (field === attachmentTypeApiField){
      if(current_roof_type === RoofType.SHINGLE && (value === AttachmentType.SFM_BUTYL_DTD || value === AttachmentType.SM_BUTYL_DTD)){
        if (field === attachmentTypeApiField  && value !== previousEnvConfig[attachmentTypeApiField]) {
          dispatch(SET_PROJECT_OPTION(roofSubstrateApiField, roof_substrate));
        }
      }
    }


    if (field === roofSubstrateApiField){
      if(isOsbOrPlywood(current_roof_type, attachment_type, roof_substrate)){
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 1));
      }
      else {
        dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', 24));
      }
    }

  }

  if (field === midClampsApiField && isSMTiltPR(productId)) {
    if (value === MidEndClampChoice.PRO_CLAMP) {
      dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
    } else if (value === MidEndClampChoice.STANDARD_CLAMP) {
      dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
    } else if (value === MidEndClampChoice.UNIVERSAL_CLAMP) {
      dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
    }
  }

  if (field === snowLoadApiField) {
    const { lowerLimit, upperLimit } = getSnowLoadLimits();
    const { projectConfiguration: { projectVersion } } = state();
    if (isRM10(productId) || applyCentralSupportForRM5(projectVersion) || isRM10Evolution(productId)) {
      const moduleLength = store.getState().moduleSelector.modelData.height;
      if (checkCentralSupportConditionForSnowloadAndHeight(current_snow_load, moduleLength)) {
        dispatch(SET_PROJECT_OPTION(centralSupportApiField, 1));
      } else {
        dispatch(SET_PROJECT_OPTION(centralSupportApiField, 0));
      }
    }
    if (value < lowerLimit || value > upperLimit) {
      dispatch(SET_FIELD_INVALID(snowLoadApiField, `Snow load must lie between ${lowerLimit} and ${upperLimit}.`));
    }
  }

  if (field === windSpeedApiField && value !== previousEnvConfig.wind_speed) {
    validateWindSpeed(value);
  }
  else if (field == buildingHeightApiField || field == longestBuildingLengthApiField || field == shortestBuildingLengthApiField || field == parapetHeightNumeric.apiField) {
    const { projectConfiguration: { projectVersion } } = state();
    if (isAscender(productId) || isMetricUnit(inputUnit)) {
      if (value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 1) {
        dispatch(SET_PROJECT_OPTION(field, parseFloat(value).toFixed(1)));
      } else {
        dispatch(SET_PROJECT_OPTION(field, value));
      }
    }
    else {
      dispatch(SET_PROJECT_OPTION(field, parseInt(String(value))));
    }

    if (field == buildingHeightApiField) {
      validateBuildingHeight(value);
    }
    else if (field == parapetHeightNumeric.apiField) {
      validateParapetHeightNumeric(value);
    }
    else if (field === longestBuildingLengthApiField) {
      validateLongestBuildingLength(value);
      if (isEcoFoot2Plus(productId) || isSMTiltPR(productId) || isAscender(productId) || applyShortestBuildingLength(projectVersion) || (isASCE722(current_building_code) && (isNxtHorizon(productId)|| isSolarMount(productId)))) {
        validateLongestAndShortestBuildingLength(field, value, current_shortest_building_length);
      }
    }
    else if (field === shortestBuildingLengthApiField) {
      validateShortestBuildingLength(value);
      if (isEcoFoot2Plus(productId) || isSMTiltPR(productId) || isAscender(productId) || applyShortestBuildingLength(projectVersion) || (isASCE722(current_building_code) && (isNxtHorizon(productId)|| isSolarMount(productId)))) {
        validateLongestAndShortestBuildingLength(field, current_building_length, value);
      }
    }
  }


  if (field === ballastBlockWeightApiField) {
    const { projectConfiguration: { projectVersion } } = state();
    const ballastBlockWeight = ballastBlockWeightField(inputUnit)
    const weightUnits = isMetricUnit(inputUnit) ? 'kg' : 'lbs';
    if (value < ballastBlockWeight.min || value > ballastBlockWeight.max) {
      dispatch(SET_FIELD_INVALID(ballastBlockWeightApiField, `Block Weight must lie between ${ballastBlockWeight.min} and ${ballastBlockWeight.max} ${weightUnits}`));
    }
    else if (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || applyRM5Revamp(projectVersion)) {
      if (current_is_half_block_allowed && value <= current_half_block_weight) {
        dispatch(SET_FIELD_INVALID(
          ballastBlockWeightApiField,
          `Half block weight must be less than full block weight (${value} ${weightUnits})`));
      }
      else {
        dispatch(CLEAR_INVALID_FIELD(ballastBlockWeightApiField));
      }
    }
  }

  if ((isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRMGridFlex(productId) || isRM5(productId) || isRmGridflex10(productId)) && shouldUseSetBackDistance("", productId, projectVersion)) {
    const { projectConfiguration: { formState: { invalidFields } } } = state();
    const { lowerLimit, upperLimit } = getSetbackLimits();
    const isSetbackValid = lowerLimit <= current_setback_distance && current_setback_distance <= upperLimit && (
      isMetricUnit(inputUnit) ? current_setback_distance < metersToCms(current_building_height) / 2 : current_setback_distance < current_building_height / 2);
    if (isSetbackValid && [...RM_ECOFOOT_SETBACK_AFFECTING_FIELDS, allowMechanicalAttachmentsApiField].includes(field)
      && !invalidFields.some(({ fieldName }) => RM_ECOFOOT_SETBACK_AFFECTING_FIELDS.includes(fieldName) && fieldName !== setbackDistanceApiField)
    ) {
      validateSetback(productId, currentEnvConfig, projectVersion, inputUnit);
    }
    showTornadoSpeedWarning();
  }

  if (field === rafterSpacingApiField) {
    validateRafterSpacing(value);
  }


  if (field === preferredSpanApiField || field === rafterSpacing.apiField) {
    dispatch(SET_PROJECT_OPTION(field, parseInt(String(value))));
    validatePreferredspanAndRafterSpacing(field, productId, parseInt(String(current_preferred_span)), parseInt(String(current_rafter_spacing_inches)), current_roof_type, current_attachment_type, current_roof_substrate)
  }

  if (field === preferredSpanApiField) {
    validateSpans(productId, value);
  }

};


function onSnowLoadChanged(value: string, productId: number, projectEnvConfig: projectEnvConfig) {
  if (isSFMFamily(productId)) {
    const snowLoad = parseInt(value, 10);
    const currentStaggerAttachmentsValue = projectEnvConfig.stagger_attachments;
    const staggerAttachmentsWasNotSelected = currentStaggerAttachmentsValue.toString() !== YES_OPTION.value;
    if (!isNaN(snowLoad) && shouldUseStaggerAttachmentsForSfm(snowLoad) && staggerAttachmentsWasNotSelected) {
      dispatch(SET_PROJECT_OPTION(staggerAttachments.apiField, Boolean(YES_OPTION.value)));
      showWarningAlert(`Stagger Attachments selection has been set to "${YES_OPTION.name}" due to the design snow load`);
    }
  }
}

function onStaggerAttachmentsChanged(value: string, productId: number, projectEnvConfig: projectEnvConfig) {
  if (isSFMFamily(productId)) {
    const { snow_load } = projectEnvConfig;
    if (!isNaN(snow_load) && shouldUseStaggerAttachmentsForSfm(snow_load) && value === NO_OPTION.value) {
      showWarningAlert(`Staggered attachments are recommended for snow loads greater than ${MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS} psf`);
    }
  }
}

export const updateEnviromentalFactors = (
  zipCode: string,
  building_code: number,
  productId: number,
  risk_category: number,
  newZipCode?: string,
  cords?: { lat: number, lng: number },
) => {
  const { lat, lng } = getMapCenter();
  dispatch(
    GET_ENVIRONMENTAL_FACTORS(
      cords && cords.lat || lat,
      cords && cords.lng || lng,
      newZipCode || zipCode,
      building_code || (isOnlyAsce716ProductFamily(productId) ? asce_716.value : asce_710.value),
      productId,
      risk_category,
    ),
  );
};

export const onZipCodeChange = (
  newZipCode: string,
  productId: number,
  initData: projectEnvConfig,
  mapType: string,
) => {
  const zipCodeLowerCase = newZipCode.toUpperCase();
  dispatch(SET_CITY_NAME(''));

  if (zipCodeLowerCase !== initData.zipcode) {
    dispatch(CLEAR_ENV_FACTORS_FOR());
    getAreaByZip(zipCodeLowerCase, (data: ICITY_STATE) => {
      const { address, city, lat, lng, state } = data;
      if (city.length && state.length) {
        dispatch(SET_CITY_NAME(`${city}, ${state}`));
      }

      if (address.length) {
        dispatch(SET_LOCATION(address));
      }

      changeBuildingCodeByState(state)

      if (lat !== undefined && lng !== undefined && mapType !== 'white') {
        setCenterForCurrentMap(lat, lng);
      }
      console.log(initData.imported_project);
      if (!initData.imported_project) {
        updateEnviromentalFactors(
          zipCodeLowerCase,
          initData.building_code,
          productId,
          initData.risk_category,
          zipCodeLowerCase,
          { lat, lng },
        );

      }
    });
  }
  updateProjectOption(
    'zipcode',
    newZipCode,
    initData.zipcode,
    productId,
    initData,
  );
};

export const SelectRadios = () => {
  const activeCheckboxes = getActiveCheckboxes();
  Object.keys(activeCheckboxes).map(f => {
    const field = activeCheckboxes[f];
    const radioInput = document.querySelector(`input[value="${field}"]`);

    if (radioInput) {
      const selectionControlContainer = radioInput.closest(
        '.md-selection-control-container',
      );
      if (selectionControlContainer) {
        selectionControlContainer.classList.add('active');
      }
    }
  });
};

const unselectRadioOption = (value: any) => {
  const radio = document.querySelector(`input[value="${value}"]`);
  if (radio && radio.closest('.md-selection-control-group')) {
    const radios = radio.closest('.md-selection-control-group').children;
    Array.from(radios).forEach(el => el.classList.remove('active'));
  }
};

const setResidentialsExtraInputsValueForAsce716 = () => {
  dispatch(SET_PROJECT_OPTION('building_type', gable.value));
  dispatch(SET_PROJECT_OPTION('pro_clamps', 1));
  dispatch(SET_PROJECT_OPTION('risk_category', 2));
};

const unsetResidentialsExtraInputsValueForAsce716 = (productId: number) => {
  dispatch(REMOVE_OPTION_FROM_CONFIG('pro_clamps'));
  !isRMFamily(productId) && !isEcoFoot2Plus(productId) && dispatch(REMOVE_OPTION_FROM_CONFIG('risk_category'));
};

const setExtraInputsValueForAsce722 = () => {
  dispatch(SET_PROJECT_OPTION(seismicSds.apiField, seismicSds.min));
  dispatch(SET_PROJECT_OPTION(seismicSd1.apiField, seismicSd1.min));
  dispatch(SET_PROJECT_OPTION(tornadoSpeed.apiField, tornadoSpeed.min));
  dispatch(SET_PROJECT_OPTION(longTransitionPeriods.apiField, longTransitionPeriods.min));
  dispatch(SET_PROJECT_OPTION(longestRowLength.apiField, longestRowLength.min));
  dispatch(SET_PROJECT_OPTION(shortestRowLength.apiField, shortestRowLength.min));
};

const unsetExtraInputsValueForAsce722 = () => {
  dispatch(REMOVE_OPTIONS_FROM_CONFIG(seismicSds.apiField, seismicSd1.apiField, tornadoSpeed.apiField, longTransitionPeriods.apiField, longestRowLength.apiField, shortestRowLength.apiField));
};

const setProperRoofPitchForResidentialsASCE716 = (buildingCode: number, buildingType: number) => {
  const { projectConfiguration: { productId } } = state()
  if ((isASCE716or722BuildingCode(buildingCode) && isFlatRoof(buildingType)) || isSMTiltPR(productId)) {
    dispatch(SET_TILTED_ROOF(null));
  } else {
    if (isRM5(productId) || isRM10orRM10Evo(productId)) {
      dispatch(SET_TILTED_ROOF('0'));
    } else {
      dispatch(SET_TILTED_ROOF('---'));
    }
  }
};

export const validateSetback = (productId: number, projectEnvConfig: projectEnvConfig, projectVersion: string, inputUnit: number) => {
  if (applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) || isRmGridflex10(productId)) {
    const seismic_setback_distance = isMetricUnit(inputUnit) ? round(feetsToCms(getSeismicSetback(projectEnvConfig, productId)), 1) : round(getSeismicSetback(projectEnvConfig, productId), 1);
    const seismic_setback_satisfied = projectEnvConfig.setback_distance >= seismic_setback_distance;
    changeSetbackDistanceWarning(seismic_setback_distance, seismic_setback_satisfied, productId);
  }
}


const validateLongestBuildingLength = (value: number) => {
  const { lowerLimit, upperLimit } = getLongestBuildingLengthLimits();
  dispatch(CLEAR_INVALID_FIELD(longestBuildingLengthApiField));
  if (value < lowerLimit || value > upperLimit) {
    dispatch(SET_FIELD_INVALID(longestBuildingLengthApiField, `Longest Building Length must lie between ${lowerLimit} and ${upperLimit}`));
  }
}

const validateShortestBuildingLength = (value: number) => {
  const { lowerLimit, upperLimit } = getShortestBuildingLengthLimits();
  dispatch(CLEAR_INVALID_FIELD(shortestBuildingLengthApiField));
  if (value < lowerLimit || value > upperLimit) {
    dispatch(SET_FIELD_INVALID(shortestBuildingLengthApiField, `Shortest Building Length must lie between ${lowerLimit} and ${upperLimit}`));
  }
}

const validateBuildingHeight = (value: number) => {
  const { lowerLimit, upperLimit } = getBuildingHeightLimits();
  dispatch(CLEAR_INVALID_FIELD(buildingHeightApiField));
  if (value < lowerLimit || value > upperLimit) {
    dispatch(SET_FIELD_INVALID(buildingHeightApiField, `Building height must lie between ${lowerLimit} and ${upperLimit}`));
  }
}

const validateParapetHeightNumeric = (value: number) => {
  dispatch(CLEAR_INVALID_FIELD(parapetHeightNumeric.apiField));
  if (value < parapetHeightNumeric.min || value > parapetHeightNumeric.max) {
    dispatch(SET_FIELD_INVALID(parapetHeightNumeric.apiField, `Parapet height must lie between ${parapetHeightNumeric.min} and ${parapetHeightNumeric.max}`));
  }
}

const validateWindSpeed = (value: number) => {
  const { lowerLimit, upperLimit } = getWindSpeedLimits();
  dispatch(CLEAR_INVALID_FIELD(windSpeedApiField));
  if (value < lowerLimit || value > upperLimit) {
    dispatch(SET_FIELD_INVALID(windSpeedApiField, `Wind speed must lie between ${lowerLimit} and ${upperLimit}.`));
  }
}

const validateLongestAndShortestBuildingLength = (field: string, longest_building_length: number, shortest_building_length: number) => {
  const { lowerLimit: lowerLimitForShortest, upperLimit: upperLimitForShortest } = getShortestBuildingLengthLimits();
  const { lowerLimit: lowerLimitForLongest, upperLimit: upperLimitForLongest } = getLongestBuildingLengthLimits();
  if (longest_building_length < lowerLimitForLongest || longest_building_length > upperLimitForLongest ||
    shortest_building_length < lowerLimitForShortest || shortest_building_length > upperLimitForShortest) {
    return;
  }
  if (longest_building_length < shortest_building_length) {
    if (field == longestBuildingLengthApiField && longest_building_length) {
      dispatch(SET_FIELD_INVALID(longestBuildingLengthApiField, `Longest Building Length must be greater than Shortest Building Length`));
      dispatch(CLEAR_INVALID_FIELD(shortestBuildingLengthApiField));
    }
    else if (field == shortestBuildingLengthApiField && shortest_building_length) {
      dispatch(CLEAR_INVALID_FIELD(longestBuildingLengthApiField));
      dispatch(SET_FIELD_INVALID(shortestBuildingLengthApiField, `Shortest Building Length must be smaller than Longest Building Length`));
    }
  }
  else {
    dispatch(CLEAR_INVALID_FIELD(longestBuildingLengthApiField));
    dispatch(CLEAR_INVALID_FIELD(shortestBuildingLengthApiField));
  }
}

export const updateProjectOptionOnRailTypeChange = (allPanelsDeleted: boolean = false) => {
  const { projectConfiguration: { projectEnvConfig: { rafter_spacing_inches, building_code, roof_type }, inputUnit, railsProductId, productId, projectEnvConfig: { tilt, } } } = state();
  if (isSolarMount(productId) && tilt === null) {
    if (isSMAscenderFlush(railsProductId)) {
      dispatch(SET_PROJECT_OPTION(buildingCodeApiField, isASCE722(building_code) ? building_code : asce_716.value));
      dispatch(SET_PROJECT_OPTION(midClampsApiField, smClampsOptions.universalClamps.value));
      dispatch(SET_PROJECT_OPTION(attachmentTypeApiField, AttachmentType.L_FOOT_ONLY));
      showErrorAlert('Only ASCE 716 and ASCE 722 building code is available for this selection');
      dispatch(SET_PROJECT_OPTION(rafterSpacingApiField, 61));
      dispatch(SET_PROJECT_OPTION(railFinishApiField, 'AL'));
      dispatch(SET_PROJECT_OPTION(meanRecurrenceApiField, '50'));
      dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, get_design_life_factor(50)));
      dispatch(SET_PROJECT_OPTION(riskCategoryApiField, RiskCategory.II));
      dispatch(SET_PROJECT_OPTION(residentalBuildingTypes.apiField, gable.value));
      if(roof_type !== RoofType.SHINGLE){
        dispatch(SET_PROJECT_OPTION(roofTypeApiField, RoofType.SHINGLE));
      }
      if (!allPanelsDeleted) {
        switchProjectToASCE716(productId);
      }
      if (!isMetricUnit(inputUnit)) {
        dispatch(TOGGLE_INPUT_UNIT());  // switching to metric unit -  this actionwill trigger updateProjectOptionOnInputUnitChange
      }
    }
    else {
      dispatch(SET_PROJECT_OPTION(rafterSpacingApiField, isMetricUnit(inputUnit) ? 61 : 24));
      validateRafterSpacing(rafter_spacing_inches);
      if (isMetricUnit(inputUnit)) {
        dispatch(TOGGLE_INPUT_UNIT());  // switching to us customary unit - this actionwill trigger setProjectOptionOnInputUnitChange
      }
    }
    validateInputs();
  }
}

export const setProjectOptionOnInputUnitChange = () => {
  const {
    projectConfiguration: {
      inputUnit,
      productId,
      projectEnvConfig: {
        preferred_span, wind_speed, building_height, shortest_building_length, building_length,
        snow_load, setback_distance, elevation: elevationNumber, block_weight, half_block_weight, parapet_height_input,
        uplift_allowable, shear_allowable, tornado_speed
      }
    }
  } = state();
  if (isMetricUnit(inputUnit)) {
    // dispatch(SET_PROJECT_OPTION(raf, isSMAscenderFlush(railsProductId) ? 1 : _.round(inchesToCms(Number(rafter_spacing_inches)), 1)));
    dispatch(SET_PROJECT_OPTION(preferredSpanApiField, _.round(inchesToCms(Number(preferred_span)), 1)));
    dispatch(SET_PROJECT_OPTION(windSpeedApiField, Math.round(milesToKms(wind_speed))));
    dispatch(SET_PROJECT_OPTION(tornadoSpeed.apiField, Math.round(milesToKms(tornado_speed))));
    dispatch(SET_PROJECT_OPTION(buildingHeightApiField, _.round(feetsToMeters(building_height), 1)));
    dispatch(SET_PROJECT_OPTION(shortestBuildingLengthApiField, _.round(feetsToMeters(shortest_building_length), 1)));
    dispatch(SET_PROJECT_OPTION(longestBuildingLengthApiField, _.round(feetsToMeters(building_length), 1)));
    dispatch(SET_PROJECT_OPTION(elevation.apiField, _.round(feetsToMeters(elevationNumber))));
    dispatch(SET_PROJECT_OPTION(snowLoadApiField, _.round(psfToKpa(snow_load), 2)));
    if (isRM10orRM10Evo(productId) || isRM5(productId) || isEcoFoot2Plus(productId)) {
      dispatch(SET_PROJECT_OPTION(setbackDistanceApiField, _.round(feetsToCms(setback_distance), 1)));
      dispatch(SET_PROJECT_OPTION(ballastBlockWeightApiField, _.round(lbsToKgs(block_weight), 1)));
      if (!isEcoFoot2Plus(productId)) {
        dispatch(SET_PROJECT_OPTION(ballastBlockHalfWeightApiField, _.round(lbsToKgs(half_block_weight), 1)));
        dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, _.round(lbsToKgs(uplift_allowable), 1)));
        dispatch(SET_PROJECT_OPTION(shearAllowableApiField, _.round(lbsToKgs(shear_allowable), 1)));
      }
    }
    if(isRM10Evolution(productId) || isRmGridflex10(productId)) {
      dispatch(SET_PROJECT_OPTION(rmIFIParapetHeightApiField, _.round(inchesToMeters(parapet_height_input), 1)));
    }
  }
  else {
    // dispatch(SET_PROJECT_OPTION(rafterSpacingApiField, isSMAscenderFlush(railsProductId) ? 1 : Math.round(cmsToInches(Number(rafter_spacing_inches)))));
    dispatch(SET_PROJECT_OPTION(preferredSpanApiField, Math.round(cmsToInches(Number(preferred_span)))));
    dispatch(SET_PROJECT_OPTION(windSpeedApiField, Math.round(kmsToMiles(wind_speed))));
    dispatch(SET_PROJECT_OPTION(tornadoSpeed.apiField, Math.round(kmsToMiles(tornado_speed))));
    dispatch(SET_PROJECT_OPTION(buildingHeightApiField, Math.round(metersToFeets(building_height))));
    dispatch(SET_PROJECT_OPTION(shortestBuildingLengthApiField, Math.round(metersToFeets(shortest_building_length))));
    dispatch(SET_PROJECT_OPTION(longestBuildingLengthApiField, _.round(metersToFeets(building_length))));
    dispatch(SET_PROJECT_OPTION(elevation.apiField, Math.round(metersToFeets(elevationNumber))));
    dispatch(SET_PROJECT_OPTION(snowLoadApiField, kpaToPsf(snow_load)));
    if (isRM10orRM10Evo(productId) || isRM5(productId) || isEcoFoot2Plus(productId)) {
      dispatch(SET_PROJECT_OPTION(setbackDistanceApiField, _.round(cmsToFeets(setback_distance), 1)));
      dispatch(SET_PROJECT_OPTION(ballastBlockWeightApiField, _.round(kgsToLbs(block_weight))));
      if (!isEcoFoot2Plus(productId)) {
        dispatch(SET_PROJECT_OPTION(ballastBlockHalfWeightApiField, _.round(kgsToLbs(half_block_weight))));
        dispatch(SET_PROJECT_OPTION(upliftAllowableApiField, _.round(kgsToLbs(uplift_allowable))));
        dispatch(SET_PROJECT_OPTION(shearAllowableApiField, _.round(kgsToLbs(shear_allowable))));
      }
    }
    if(isRM10Evolution(productId) || isRmGridflex10(productId)) {
      dispatch(SET_PROJECT_OPTION(rmIFIParapetHeightApiField, _.round(metersToInches(parapet_height_input), 1)));
    }
  }
  updateRoofDimensionsOnInputUnitChange();
  if (isSolarMount(productId))
    validateInputs();
}


const validateInputs = () => {
  const { projectConfiguration: { productId, projectEnvConfig: { building_length, shortest_building_length, building_height, parapet_height_num, wind_speed, preferred_span, rafter_spacing_inches } } } = state();
  validateBuildingHeight(building_height);
  validateParapetHeightNumeric(parapet_height_num)
  validateShortestBuildingLength(shortest_building_length);
  validateLongestAndShortestBuildingLength(longestBuildingLengthApiField, building_length, shortest_building_length);
  validateWindSpeed(wind_speed);
  if (!isCommercialProduct(productId)) {
    validateSpans(productId, preferred_span);
    validateRafterSpacing(rafter_spacing_inches);
  }
}


const validatePreferredspanAndRafterSpacing = (field, productId, preferred_span, rafter_spacing_inches, roof_type, attachment_type, roof_substrate) => {
  if (field === preferredSpanApiField) {
    if (Number(preferred_span) > 72 && isSMTiltPR(productId)) {
      dispatch(SET_FIELD_INVALID(preferredSpanApiField, "Please enter preferred span <72''"))
    }
    validateSpansMinMax(productId, preferred_span);
  }
  if (field === rafterSpacing.apiField) {
    if (Number(rafter_spacing_inches) < rafterSpacing.min || Number(rafter_spacing_inches) > rafterSpacing.max) {
      dispatch(SET_FIELD_INVALID(rafterSpacing.apiField, `rafter spacing should be ranging from ${rafterSpacing.min} and ${rafterSpacing.max}`));
    }
  }
  if (isMetalX(productId) && isSunframeMicroRail(productId)) {
    return;
  }
  validateOnPreferredSpan(preferred_span, rafter_spacing_inches);
}

const validateOnPreferredSpan = (preferred_span, rafter_spacing_inches) => {
  const errorMessage = 'preferred span must be a multiple of rafter spacing';
  if (!!preferred_span && !!rafter_spacing_inches && isSpanMultipleOfRafterSpacing(preferred_span, rafter_spacing_inches)) {
    dispatch(CLEAR_INVALID_FIELD(preferredSpanApiField));
  } else {
    dispatch(SET_FIELD_INVALID(preferredSpanApiField, errorMessage));
  }
}

const updateRoofDimensionsOnInputUnitChange = () => {
  const { roofsSelector: { mapType }, drawingManager: { roofs } } = state();
  if (roofs && !!Object.keys(roofs)?.length) {
    Object.keys(roofs).forEach(roofId => {
      if (isGoogleMap(mapType)) {
        updateRoofDimensionsGoogleMap(Number(roofId), false);
      }
      else if (isBingMap(mapType)) {
        updateRoofDimensionsBingMap(Number(roofId), false);
      }
    })
  }
}

export function productChange(field, value){
  if (value === 34) {
    dispatch(SET_PRODUCT_ID(34))
    dispatch(SET_RAILS_PRODUCT_ID(34))
    dispatch(SET_PROJECT_OPTION(field, value));
    dispatch(SET_PROJECT_OPTION(endClampsApiField, smClampsOptions.nxtHorizonHiddenEndClamps.value));
    dispatch(SET_PROJECT_OPTION(midClampsApiField, smClampsOptions.nxtHorizonComboClamps.value));
    dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
    dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
  }
  if (value === 9 ) {
    dispatch(SET_PRODUCT_ID(99))
    dispatch(SET_RAILS_PRODUCT_ID(9))
    dispatch(SET_PROJECT_OPTION(field, value));
  }
}

export const US_SHORT_STATE_NAMES={
  newMexico: 'NM',
  florida: 'FL'
}

const buildingCode716DefaultStates = [US_SHORT_STATE_NAMES.newMexico]
const buildingCode722DefaultStates = [US_SHORT_STATE_NAMES.florida]
export function changeBuildingCodeByState(stateCode: string){
  const { projectConfiguration: { productId } } = state()
  if (buildingCode716DefaultStates.includes(stateCode) && productHasAsce716BuildingCode(productId)){
    dispatch(SET_PROJECT_OPTION(buildingCodeApiField,asce_716.value))
    return asce_716.value
  } else if (buildingCode722DefaultStates.includes(stateCode) && productHasAsce722BuildingCode(productId)){
    dispatch(SET_PROJECT_OPTION(buildingCodeApiField,asce_722.value))
    return asce_722.value
  }
}

