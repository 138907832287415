import * as React from 'react';
import SVG from 'react-inlinesvg';
import Modal from 'react-bootstrap/Modal';
import { dispatch, state } from '__common/store';
import Button from 'react-md/lib/Buttons/Button';
import { HIDE_MODAL_FOR_AURORA_REPORTS, SEND_BOM_DATA_TO_AURORA, SET_AURORA_LOADER } from 'actions';
import { connect } from 'react-redux';
import { getFileNamePrefix } from 'engineering/components/engineeringProjectDocuments/utils/reportsApi';
import { getProductName } from '__common/constants/products';


type Props = {
    bom: bomState,
    showModal: boolean,
};

export default class ModalForAuroraBomReport extends React.Component<Props, {}> { 
    auroraReportCancel() {
        dispatch(HIDE_MODAL_FOR_AURORA_REPORTS());
        }
    retrySendBomData() {
        const {projectConfiguration:{projectId}} = state();
        dispatch(SEND_BOM_DATA_TO_AURORA(projectId));
        dispatch(SET_AURORA_LOADER());
      }
 render() {
    const {projectConfiguration:{ projectId, productId } } = state();
    const {bom:{auroraReport}} = this.props;
    const isError = auroraReport?.error;
    const isLoading = auroraReport?.loading;
    return (
        <>
            <Modal show={this.props.showModal} size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered dialogClassName="modal-90w custom-modal-for-aurora-bom"
            >
                <Modal.Header>
                    <h2 className="aurora-modal-heading">Download U-Builder Report For Aurora</h2>
                    {!isLoading && (<img className='close-btn-aurora-bom' onClick={this.auroraReportCancel} src={require('assets/media/icons/Icon material-cancel.png')}/>)}
                </Modal.Header>
                
                <Modal.Body >
                {isLoading ? (
                    <>
                        <div className="modal-body-for-aurora loading">
                            <div className="aurora-loading"></div>
                            <p className='aurora-loading-message'>Configuring Your Options For Better Solar</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="modal-body-for-aurora">
                            <div className="aurora-download-bom">
                                <img src={require("assets/media/icons/image_beside_download_button.png")} height={181} width={189}/>
                                <Button className="aurora-reports-download-button">
                                    <a href={this.props.bom?.auroraReport?.auroraReportsDataUrl} download={`${getFileNamePrefix(getProductName(productId), projectId)}_BOM_report_for_aurora.pdf`} className='aurora-reports-download-button-link' >
                                        <img className="download-icon" src={require("assets/media/icons/Icon-feather-download.png")} height={17} width={17}/>
                                        DOWNLOAD BOM
                                    </a>
                                </Button> 
                            </div>
                            <hr/>
                            {!isError ? (
                                <>
                                   
                                    <div className="aurora-successful">
                                        <img className="success-icon" src={require("assets/media/icons/verified_icon.png")} height={64} width={64}/>
                                        <p className="successfully-integrated-aurora">Successfully Integrated</p>
                                        <p className="successfully-integrated-aurora-paragraph">Your data to Aurora from U-Builder <br/> has been successfully exported.</p>
                                    </div>
                                </>
                                
                            ): (
                                <div className="aurora-failure">
                                    <img className="failure-icon" src={require("assets/media/icons/image_beside_retry_send_bom.png")} height={156} width={185}/>
                                    <div className="integration-failure-content">
                                        <p className="integration-failure-aurora">Unable To Connect With Aurora</p>
                                        <p className="integration-failure-aurora-paragraph">Your data connection to Aurora from U-Builder <br/> faced some error. Please Try Again.</p>
                                        <Button className="aurora-reports-retry-button">
                                            <a href="#" className="aurora-reports-retry-button-link" onClick={this.retrySendBomData}>
                                            <img className="retry-icon" src={require("assets/media/icons/Icon-feather-refresh-ccw.png")} height={17} width={17}/>
                                                Retry Connection
                                            </a>
                                        </Button>
                                    </div>
                                    
                                </div>
                            )}
                            
                        </div>
                    </>
                    
                )}
                
                </Modal.Body>
            </Modal>
        </>
      )
    }
}
