import { BG_CLEAR_LOADED } from 'actions';
import { drawPanelsOnStage, removeAllPanelsFromStageToRedraw } from '__editor/panelsEditor/components/panels/drawingPanelsOnStage';
import { setPanelsDimensions } from '__editor/panelsEditor/components/panels/panels';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { loadRoofPitch, loadStructureTilt } from '__editor/panelsEditor/components/tiltedRoof/tiltedRoof';
import { resetRTree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { state } from '__common/store';
import { updatePanelsDimensions } from '__editor/panelsEditor/components/dimensions/dimensions';
import { determineZoomLevel } from '../background/utils/determineZoomLevel';
import { getEditorDims } from '../../panelsEditorHelper';
import { renderAdvanceSelectingOnStage } from '../advanceSelecting/drawAdvanceSelectingOnStage';
import { drawObstructionsOnStage } from '../obstructions/drawObstructionsOnStage';
import { drawTooltipCursorOnStage } from '__common/components/tooltipCursor/drawTooltipCursorOnStage';
import { getRenderer } from '../renderer/renderer';
import { drawRoofZonesOnStage } from '../roofZones/drawRoofZonesOnStage';
import { getBaysContainer, getPanelsContainer } from '../panels/utils/panelsContainer';
import { loadPanels } from '../panels/utils/panelsManagment';
import { RESTORE_PANELS_COLLISIONS_BOUNDS } from '../panels/panelsActions';
import { isEqual } from 'lodash';
import { arrayDisplacementKeyboard } from '__editor/panelsEditor/keyboardConfg';
import { rotateBgToLeadEdge } from '../leadEdge/leadEdge';
import { drawingMeasurementToolOnStage } from '../measurementTool/measurementToolOnStage';
import _ from 'lodash';
import { isBlankMap } from '__common/constants/map';

export function panelsEditorDidUpdate(
  prevProps: panelsEditorComponentState, 
  currentProps: panelsEditorComponentState, 
  prevState, 
  currentState,
  outerDiv,
  runEditor: () => void,
  resetZoom: () => void, 
  redrawBackground: (backgroundState: backgroundState) => void,
  redrawCursor: () => void,
  drawRubberBand: (prevProps: any) => void,
  redrawLeadEdge: () => void,
  resizeBackgroundContainer: () => void,
  drawingScaleToolOnStage: () => void,
  redrawRotatedLeadEdge: () => void,
  drawRoofDimensions: () => void,
) {
  const { dispatch } = currentProps;
  // window.alert('inside')

  if (!currentState.isImgLoading && currentState.isImgLoading !== prevState.isImgLoading && outerDiv) {
    runEditor();
  }

  if (prevProps.roofZones !== currentProps.roofZones) {
    drawRoofZonesOnStage();
  } 

  if (!isEqual(prevProps.advanceRoofSelecting, currentProps.advanceRoofSelecting)) {
    renderAdvanceSelectingOnStage();
  }

  if(!isEqual(prevProps.measurementTool, currentProps.measurementTool)){
    drawingMeasurementToolOnStage();
  }

  if (prevProps.obstructions !== currentProps.obstructions || prevProps.background.selectedRoofId !== currentProps.background.selectedRoofId) {
    drawObstructionsOnStage();
    drawRoofZonesOnStage();
  }

  if (prevProps.tooltipCursor !== currentProps.tooltipCursor) {
    const renderer = getRenderer();
    const stage = getStage();

    drawTooltipCursorOnStage(renderer, stage);
  }


  if (prevProps.background.moveArrayMode  !== currentProps.background.moveArrayMode ||  !_.isEqual(prevProps.panels.panelsToBeMoved, currentProps.panels.panelsToBeMoved)) {
    arrayDisplacementKeyboard();
  }

  if (shouldRedrawAllPanels(prevProps, currentProps.panels)) {
    removeAllPanelsFromStageToRedraw(getPanelsContainer(), getBaysContainer());
    drawPanelsOnStage(getStage(), getPanelsContainer(), getBaysContainer(), currentState.panelsDict);
    updatePanelsDimensions();
  }

  if (shouldRedrawBackground(prevProps)) {
    const { background: { metersPerPixel }, settings: { rowSpacing, columnSpacing } } = currentProps;
    dispatch(BG_CLEAR_LOADED());
    resetZoom();
    redrawBackground(prevProps.background);
    resetRTree();

    if (metersPerPixel > 0) {
      dispatch(RESTORE_PANELS_COLLISIONS_BOUNDS(rowSpacing, columnSpacing, metersPerPixel));
    }

    drawRoofZonesOnStage();
  }

  if (shouldRedrawRoofEdgesOnBlankDesign(prevProps)) {
    // unlike redrawing a whole background
    // this time we don't change any properties except the roof edges
    // which are only virtual edges (i.e. we don't detect collisions with them).
    redrawBackground(prevProps.background);
  }

  if (moduleSelectorOrBackgroudnChanged(prevProps)) {
    setPanelsDimensions();
  }

  if (shouldRedrawCursor(prevProps, currentProps)) {
    redrawCursor();
  }
  if (shouldDrawPanels(prevProps, currentProps) || toggleShowingBays(prevProps, currentProps)) {
    drawPanelsOnStage(getStage(), getPanelsContainer(), getBaysContainer(), currentState.panelsDict);
    updatePanelsDimensions();
  }

  if (shouldRedrawRubberBand(prevProps, currentProps)) {
    drawRubberBand(prevProps);
  }


  if (prevProps.background.selectedRoofId !== currentProps.background.selectedRoofId || prevProps.background.metersPerPixel !== currentProps.background.metersPerPixel) {
    const { roofsSelector : { mapType } } = state()
    const { width, height } = getEditorDims();
    // if (!isBlankMap(mapType)) {
    //   determineZoomLevel(width, height);
    // }
    determineZoomLevel(width, height);
    resetRTree();
    loadPanels();
  }

  if (shouldRotateBg(prevProps)) {
    rotateBgToLeadEdge();
  }


  if (shouldUpdateLeadEdge(prevProps)) {
    redrawLeadEdge();
    redrawRotatedLeadEdge();
  }

  if (shouldChangeRoofPitch(prevProps)) {
    loadRoofPitch();  
    loadStructureTilt();
  }

  if (shouldResizeBackground(prevProps, currentState)) {
    resizeBackgroundContainer();
    drawRoofZonesOnStage();
  }

  if (shouldRedrawScaleTool(prevProps, currentProps)) {
    drawingScaleToolOnStage();
  }

  if (shouldRedrawRoofDimensions(prevProps, currentProps)){
    drawRoofDimensions();
  }
}

export const moduleSelectorOrBackgroudnChanged = (prevState: panelsEditorComponentState) => {
  const newState = state();
  const prevModuleSelector = prevState.moduleSelector;
  return prevModuleSelector.modelData !== newState.moduleSelector.modelData || shouldRedrawBackground(prevState);
};

export const shouldUpdateComponent = (oldProps: panelsEditorComponentState, oldState, newState) => {
  const newProps = state();
  return oldProps.panels !== newProps.panels 
    || oldProps.drawingManager !== newProps.drawingManager
    || oldProps.rubberBand !== newProps.rubberBand
    || oldProps.leadEdgeRoofSelector !== newProps.leadEdgeRoofSelector
    || oldProps.settings !== newProps.settings
    || oldProps.background !== newProps.background
    || oldProps.moduleSelector !== newProps.moduleSelector
    || oldProps.measurementTool !== newProps.measurementTool
    || oldProps.tiltedRoof !== newProps.tiltedRoof
    || oldProps.editorCursor.colour !== newProps.editorCursor.colour
    || oldState.isImgLoading !== newState.isImgLoading
    || oldProps.rubberBand.mode !== newProps.rubberBand.mode
    || oldProps.scale !== newProps.scale
    || oldProps.background.selectedRoofId !== newProps.background.selectedRoofId
    || oldProps.advanceRoofSelecting !== newProps.advanceRoofSelecting 
    || oldProps.obstructions !== newProps.obstructions
    || oldProps.tooltipCursor !== newProps.tooltipCursor
    || oldProps.roofZones !== newProps.roofZones
    || oldState.zoomedIn !== newState.zoomedIn
    || oldProps.stage !== newProps.stage;
};

const roofChanged = (oldState: panelsEditorComponentState) => {
  const newState = state();
  return oldState.background.selectedRoofId !== newState.background.selectedRoofId;
};

const shouldUpdateLeadEdge = (oldState: panelsEditorComponentState) => {
  const { background: { selectedRoofId } ,leadEdgeRoofSelector: { leadEdges }, roofsSelector: { mapType } } = state();
  return oldState.leadEdgeRoofSelector.leadEdges[selectedRoofId] !== leadEdges[selectedRoofId] && mapType !== 'white';
};

const shouldResizeBackground = (prevState: panelsEditorComponentState, currentState) => {
  const { background: { selectedRoofId } ,tiltedRoof: { roofPitch, backgroundScaling, enabled }, moduleSelector: { modelData } } = prevState;
  const newRoofPitch = state().tiltedRoof.roofPitch;
  const newModelData = state().moduleSelector.modelData;
  const newEnabled = state().tiltedRoof.enabled;
  const newSelectedRoofId = state().background.selectedRoofId;
  const newBackgroundScaling = state().tiltedRoof.backgroundScaling;
  return (roofPitch !== newRoofPitch || modelData !== newModelData || newEnabled !== enabled || backgroundScaling !== newBackgroundScaling || selectedRoofId !== newSelectedRoofId) && !currentState.isImgLoading;
};

const shouldChangeRoofPitch = (prevState: panelsEditorComponentState) => {
  const { background: { selectedRoofId } } = state();
  return selectedRoofId !== prevState.background.selectedRoofId;
};

const shouldRotateBg = (prevState: panelsEditorComponentState) => {
  const { background: { panelsRotationDegrees } } = state();
  return panelsRotationDegrees !== prevState.background.panelsRotationDegrees;
};

const shouldRedrawAllPanels = (prevState: panelsEditorComponentState, panelsState: any) => 
prevState.panels.enabledRoofZones !== panelsState.enabledRoofZones; 

const shouldRedrawRubberBand = (prevState, newState) => {
  const prevRubberBandState = prevState.rubberBand;
  return prevRubberBandState !== newState.rubberBand;
};


const shouldRedrawBackground = (prevState: panelsEditorComponentState) => {
  const prevBackgroundState = prevState.background;
  const currBackgroundState = state().background;
  return (
    prevBackgroundState.backgroundType !== currBackgroundState.backgroundType ||
    prevBackgroundState.cords !== currBackgroundState.cords ||
    prevBackgroundState.rotationDegrees !== currBackgroundState.rotationDegrees ||
    prevBackgroundState.bgResized !== currBackgroundState.bgResized ||
    prevBackgroundState.zoom !== currBackgroundState.zoom || 
    prevBackgroundState.selectedRoofId !== currBackgroundState.selectedRoofId
  ) && getStage();
};
  
const isPixelPointDifferent = (point1: pixelPoint, point2: pixelPoint): boolean => {
  return point1.x !== point2.x || point1.y !== point2.y;
};

const shouldRedrawRoofEdgesOnBlankDesign = (prevState: panelsEditorComponentState) => {
  const prevBackgroundState = prevState.background;
  const currBackgroundState = state().background;
  const roofEdgesHaveChanged = (
    currBackgroundState.roofEdgesPixiCords && 
    (!prevBackgroundState.roofEdgesPixiCords || 
      prevBackgroundState.roofEdgesPixiCords &&
       currBackgroundState.roofEdgesPixiCords.length !== prevBackgroundState.roofEdgesPixiCords.length || 
      prevBackgroundState.roofEdgesPixiCords &&
       currBackgroundState.roofEdgesPixiCords.some((point, index) => isPixelPointDifferent(point, prevBackgroundState.roofEdgesPixiCords[index]))
    )
  );

  return (
    roofEdgesHaveChanged && getStage()
  );
};

const shouldRedrawCursor = (prevState: panelsEditorComponentState, newState: panelsEditorComponentState) => {
  const prevEditorCursorState = prevState.editorCursor;
  const prevSettings = prevState.settings;
  const prevModuleSelector = prevState.moduleSelector;
  const prevMode = prevState.rubberBand.mode;
  const prevScaleEnabled = prevState.scale.enabled;
  const prevDesiredTableLength = prevState.panels.desiredTableLength;
  const prevDesiredTableWidth = prevState.panels.desiredTableWidth;
  const prevStatePanelsToBeMoved = prevState.panels.panelsToBeMoved
  return prevEditorCursorState.landscape !== newState.editorCursor.landscape ||
    prevEditorCursorState.colour !== newState.editorCursor.colour ||
    prevModuleSelector !== newState.moduleSelector ||
    prevSettings !== newState.settings ||
    prevMode !== newState.rubberBand.mode ||
    prevScaleEnabled !== newState.scale.enabled ||
    prevDesiredTableLength !== newState.panels.desiredTableLength ||
    prevDesiredTableWidth !== newState.panels.desiredTableWidth ||
    !_.isEqual(prevStatePanelsToBeMoved, newState.panels.panelsToBeMoved)
};

const shouldDrawPanels = (prevState: panelsEditorComponentState, newState: panelsEditorComponentState) => {
  const prevPanelsState = prevState.panels;

  return (
    !_.isEqual(prevPanelsState.panels.length, newState.panels.panels.length) || 
    prevPanelsState.panels !== newState.panels.panels || 
    !_.isEqual(prevPanelsState, newState.panels) ||
    prevState.background.toggleBaysContainer !== newState.background.toggleBaysContainer
  );
};

const toggleShowingBays = (prevState: panelsEditorComponentState, newState: panelsEditorComponentState) => {
  const toggleAttachments = prevState.background.toggleAttachments;
  return toggleAttachments === newState.background.toggleAttachments
}

const shouldRedrawScaleTool = (prevProps, newProps) => {
  if (!prevProps) {
    return;
  }
  const { scale: { endPos, enabled } } = newProps;
  const prevEndProps = prevProps.scale.endPos;
  return enabled && prevEndProps.x !== endPos.x || prevEndProps.y !== endPos.y;
};

const shouldRedrawRoofDimensions = (prevState: panelsEditorComponentState, newState: panelsEditorComponentState) => {
  return (
    (prevState.stage !== newState.stage 
    || prevState.background?.showRoofDimensions !== newState.background?.showRoofDimensions)
    && !isBlankMap(newState.roofsSelector.mapType)
  );
};
function shouldRecreateBays(prevProps, currentProps): boolean {
  return !isEqual(prevProps.panels, currentProps.panels)
}

