import * as React from 'react';
import AppHistory from '__common/modules/history';
import BackButton from 'app/components/FooterComponent/BackButton';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import Loader from '__common/components/LoaderComponent';
import ProjectConfiguration from 'projectDesign/components/projectConfiguration/projectConfigurationComponent';
import ProjectInfoHeaderMiddleBar from 'projectDesign/components/ProjectInfoHeaderMiddleBar';
import { connect } from 'react-redux';
import { loadDefaultModuleAroura } from '__common/components/moduleSelector/moduleSelector';
import { getProductTitle } from '__common/constants/products_titles';
import { SET_PRODUCT_ID, SET_PROJECT_OPTION, UPDATE_AURORA_PROJECT_CONFIGURATION } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { isNxtHorizon, products } from '__common/constants/products';
import {
  CLEAR_PROJECT_CONFIGURATION,
  RESET_MODULE_SELECTOR,
  SET_PRODUCT_TITLE,
  CLEAR_ROOFS_SELECTOR,
  IMPORT_AURORA_FULL_DESIGN_PROJECT,
  SET_ROW_SPACING,
  SET_COLUMN_SPACING,
  CLEAR_AURORA_BACKGROUND_VISIBLE,
} from 'actions';
import { roofsSelectorState } from '__editor/components/roofsSelector/roofsSelectorReducer';
import { setProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { parseDataForAuroraImport } from 'auroraImport/parseDataForAuroraImport';
import NxtHorizon from 'projectDesign/components/projectConfiguration/utils/defaultProps/NxtHorizon';
import SM from 'projectDesign/components/projectConfiguration/utils/defaultProps/sm';
import { inchesToMeters } from '__common/calculations/inchesToMeters';



type Props = {
  dispatch: Function;
  match: any;
  moduleSelector: moduleSelectorState;
  projectConfiguration: projectConfigurationState,
  roofsSelector: roofsSelectorState,
  auroraDesignConfig: auroraDesignConfigState
};


export class AuroraDesignConfig extends React.Component<Props, {}> {

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const {  projectConfiguration, auroraDesignConfig:{isAuroraBackgroundImageReady} } = this.props
    
    if (projectConfiguration.productId) {
        dispatch(SET_PRODUCT_TITLE(getProductTitle(projectConfiguration.productId)));
        setProductSettings(projectConfiguration.productId, 'white', false);
        if (isNxtHorizon(projectConfiguration.productId)) {
          dispatch(UPDATE_AURORA_PROJECT_CONFIGURATION(NxtHorizon()))
        } else {
          dispatch(UPDATE_AURORA_PROJECT_CONFIGURATION(SM()))
        }
        loadDefaultModuleAroura(getKeyByValue(products, projectConfiguration.productId))
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { dispatch, roofsSelector: { mapTypeSelected }, projectConfiguration:{ productId} } = prevProps;
    const {  projectConfiguration } = this.props
    const { projectConfiguration : { projectEnvConfig : { aurora_racking_type } }} = this.props


    if (projectConfiguration.productId !== productId) {
      dispatch(SET_PRODUCT_TITLE(getProductTitle(!(productId || projectConfiguration.productId) ? aurora_racking_type :  projectConfiguration.productId)));
      setProductSettings(projectConfiguration.productId, 'white', false);
      dispatch(SET_PROJECT_OPTION('aurora_racking_type', projectConfiguration.productId))
      if (isNxtHorizon(projectConfiguration.productId)) {
        dispatch(UPDATE_AURORA_PROJECT_CONFIGURATION({...projectConfiguration.projectEnvConfig,...NxtHorizon()}))
      } else {
        dispatch(UPDATE_AURORA_PROJECT_CONFIGURATION({...projectConfiguration.projectEnvConfig,...SM()}))
      }
      getKeyByValue(products, projectConfiguration.productId)

  }

  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CLEAR_PROJECT_CONFIGURATION());
    dispatch(RESET_MODULE_SELECTOR());
    dispatch(CLEAR_ROOFS_SELECTOR());
  }

  updateInitData = (field, value) => {
    const { dispatch } = this.props;
    dispatch(SET_PROJECT_OPTION(field, value));
    if (field === 'aurora_racking_type') {
        dispatch(SET_PRODUCT_ID(value))
      }
    if (field === 'mid_clamps_choices') {
      if (value === 1) {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if (value === 2) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      } else if (value === 3) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      } else {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      }
    }
  }

  renderImage = (image)=>{
    const img = new Image();
    img.src = image;
    img.onload = () => {
        const element = document.querySelector<HTMLElement>(".aurora-image");
        if (element) {
            element.style.background = `url('${image}') no-repeat`;
        }
    };
  }

  submitForm = () => {
    const {
      dispatch,
      projectConfiguration: { projectEnvConfig },
      projectConfiguration: { productId },
      auroraDesignConfig: { isImportingNow },
      moduleSelector,
    } = this.props;

    dispatch(CLEAR_AURORA_BACKGROUND_VISIBLE());

    const data = parseDataForAuroraImport(
      productId,
      projectEnvConfig,
      moduleSelector.modelData,
    );
    
    dispatch(IMPORT_AURORA_FULL_DESIGN_PROJECT(data))
  }

  redirectToDesign(projectId) {
    const{ projectConfiguration : {productId }} = this.props
    AppHistory.push(`/project/design/${getKeyByValue(products, productId)}/${projectId}`);
    return null;
  }

  renderLoader() {
    return <Loader />;
  }

  renderFooter = () => {
    const {
      dispatch,
      match: {
        params: { projectId },
      },
    } = this.props;

    return (
      <div className="footer-buttons">
        <BackButton
          url={`/hs/project/${projectId}`}
          dispatch={dispatch}
        />
      </div>
    );
  }

  renderConfigurator() {
    const { projectEnvConfig } = this.props.projectConfiguration;
    const {
      match: {
        params: { productId, projectId },
      },
    } = this.props;
    return (
      <div className="app-content">
        <Header navigation={true} Infobar={<ProjectInfoHeaderMiddleBar projectId={projectId} />} productId={productId} projectId={projectId} />
        <div className="aurora-design-config">
        <div
            className="aurora-image"
            style={{
              background: `url(${
                projectEnvConfig['aroura_design_screenshot']
              }) no-repeat`,
            }}
        />
          <ProjectConfiguration
            productId={productId}
            initData={projectEnvConfig}
            onChange={this.updateInitData}
            isAurora={true}
            onSave={this.submitForm}
          />
          <Footer />
        </div>
      </div>
    );
  }

  render() {
    const {
      auroraDesignConfig: { successfullyImportedProjectId, isImportingNow, isAuroraBackgroundImageReady },
      projectConfiguration: { projectEnvConfig, productId:product_id },
      roofsSelector: { mapTypeSelected },
      match: {
        params: { productId, projectId },
      },
      dispatch
    } = this.props;

    if (successfullyImportedProjectId.length) {
      return this.redirectToDesign(successfullyImportedProjectId);
    }

    if (Object.keys(projectEnvConfig).length) {
      return this.renderConfigurator();
    }
    return (
      <div className="app-content helioscope-design-config">
        <Header navigation={true} Infobar={<ProjectInfoHeaderMiddleBar projectId={projectId} />} productId={productId} projectId={projectId} />
        {this.renderLoader()}
        <Footer />
      </div>
    );
  }
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function mapStateToProps(state: appState) {
  return {
    projectConfiguration: state.projectConfiguration,
    moduleSelector: state.moduleSelector,
    roofsSelector: state.roofsSelector,
    auroraDesignConfig: state.auroraDesignConfig
  };
}

export default connect(mapStateToProps)(AuroraDesignConfig);
