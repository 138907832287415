import keyMirror from 'keymirror';

export const BomActionTypes = keyMirror({
  ADD_PART_TO_BOM: undefined,
  ADD_RM10_MODULE_AND_HEX_BOLT: undefined,
  ADD_RM10_EVO_MODULE_CLAMP_KIT: undefined,
  ADD_ATTACHMENT_TO_BOM: undefined,
  ADD_CLAMPS: undefined,
  RAIL_TYPE_LOADED: undefined,
  ADD_FRONT_TRIM_END_CAP: undefined,
  REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY:undefined,
  ADD_RAILS_TO_BOM: undefined,
  ADD_TILE_HOOK: undefined,
  ADD_FLASHKIT_PRO: undefined,
  ADD_FLASHLOC_COMP_KIT: undefined,
  ADD_LFOOT: undefined,
  ADD_TILE_REPLACEMENT_ITEM: undefined,
  ADD_STRONGHOLD_ATT_KIT_COMP : undefined,
  ADD_FLASHKIT_PRO_SB: undefined,
  ADD_STRONGHOLD_ATT_COMP_NON_SEALANT: undefined,
  ADD_NXT_CLAMPS:undefined,
  ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK: undefined,
  ADD_RM10_EVO_MODULE_CLAMP_BULK: undefined,
  ADD_T_BOLTS_009020S_FOR_SOLARHOOKS: undefined,

  REMOVE_PART_FROM_BOM: undefined,
  REMOVE_FRONT_TRIM_END_CAP: undefined,
  REMOVE_TILE_HOOK: undefined,
  REMOVE_LFOOT: undefined,
  REMOVE_FLASHKIT_PRO: undefined,
  REMOVE_FLASHKIT_PRO_SB: undefined,
  REMOVE_FLASHLOC_COMP_KIT: undefined,
  REMOVE_TILE_REPLACEMENT_ITEM: undefined,
  REMOVE_STRONGHOLD_ATT_KIT_COMP : undefined,
  REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT: undefined,

  FETCH_RM10_MODULE_AND_HEX_BOLT: undefined,
  FETCH_RM10_EVO_MODULE_CLAMP_KIT: undefined,
  FETCH_FRONT_TRIM_END_CAP: undefined,
  FETCH_STANDARD_CLAMPS: undefined,
  FETCH_PRO_CLAMPS: undefined,
  FETCH_BOM_PART_LIST_REQUEST: undefined,
  FETCH_BOM_PART_LIST_ERROR: undefined,
  FETCH_BOM_PART_LIST_SUCCESS: undefined,
  FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK:undefined,
  FETCH_RM10_EVO_MODULE_CLAMP_BULK:undefined,
  FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS:undefined,
  FETCH_SCREENSHOT_REQUEST: undefined,
  FETCH_SCREENSHOT_SUCCESS: undefined,
  SELECT_LAG_BOLTS: undefined,
  SELECT_ATTACHMENT_TYPE: undefined,
  SELECT_TILE_REPLACEMENT_TYPE: undefined,
  SELECT_ATTACHMENT: undefined,
  SELECT_ATTACHMENT_TYPE_ON_LOAD: undefined,
  SELECT_ACCESSORY: undefined,
  SELECT_STRONGHOLD_ATTACHMENT: undefined,

  UNSELECT_LAG_BOLTS: undefined,
  UNSELECT_ACCESSORY: undefined,

  BOM_RESET: undefined,
  SET_NEW_PART_QUANTITY: undefined,

  ENABLE_LAG_BOLTS: undefined,
  ENABLE_PART: undefined,

  DISABLE_LAG_BOLTS: undefined,
  DISABLE_PART: undefined,

  SET_BOM_PRODUCT: undefined,

  CHANGE_BOM_COLOR_VARIANT: undefined,

  SET_BOM_PK: undefined,
  CLEAR_BOM_PK: undefined,

  SAVE_BOM: undefined,
  AUTO_SAVE_BOM: undefined,
  SAVE_BOM_AND_DOWNLOAD_BOM_PDF: undefined,
  SEND_BOM_DATA_TO_AURORA: undefined,
  SEND_BOM_DATA_TO_AURORA_SUCCESS: undefined,
 
  BOM_SELECTION_LOADING_START: undefined,
  BOM_SELECTION_LOADING_STOP: undefined,

  GO_TO_ENGINEERING_FROM_BOM: undefined,
  DONT_GO_TO_ENGINEERING_FROM_BOM: undefined,

  REMOVE_PART_FROM_REMEMBERED_QTYS: undefined,

  BOM_CHANGED: undefined,

  CLAMPS_CHANGED: undefined,

  SELECT_ROOF_ATTACHMENT_TYPE: undefined,
  ADD_ATTACHMENT_ITEM: undefined,
  REMOVE_ATTACHMENT_ITEM: undefined,
  UPDATE_QUANTITY: undefined,
  SET_AURORA_REPORTS_DATA_URLS: undefined,
  SHOW_MODAL_FOR_AURORA_REPORTS: undefined,
  HIDE_MODAL_FOR_AURORA_REPORTS: undefined,
  ENABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA: undefined,
  DISABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA: undefined,
  SET_AURORA_LOADER: undefined,
  CLEAR_AURORA_LOADER: undefined,
  SET_AURORA_ERROR: undefined,
  CLEAR_AURORA_ERROR: undefined,
});
