import React from 'react';
import { renderInputsForAurora } from './renderInputs';
import { products } from '__common/constants/products';
import { state } from '__common/store';
import { metersToInches } from '__common/calculations/metersToInches';

export const auroraPage = (
  currentConfig: Function,
  productId: string,
  initData: projectEnvConfig,
  mapType: string,
  location: string,
  onChange: Function,
  getErrorMessageIfInvalid: (fieldName: string) => string | null,
) => {
  const buildingCode = initData['building_code'];
  const config = currentConfig(buildingCode);
  


  return {
    content: (
      <>
        <div className="drawer-section-title">Aurora Configuration</div>
        {auroraImportWarnings(initData, productId)}
        <div className="input-soft-warning">
          Before importing the project check parameters below:
        </div>
        <div className="drawer-fields helioscope-drawer-field">
          <div>{renderInputsForAurora(config.Aurora, mapType, location, initData, products[productId], onChange, getErrorMessageIfInvalid)}</div>
        </div>
      </>
    ),
    tooltip: 'Aurora Configuration',
  };
};

const auroraImportWarnings = (initData, productId: string) => {
  const { settings } = state();
  return (
    <>
      { settings?.rowSpacing && 
      (<div className="input-warning">
        Project row spacing will be updated to {metersToInches(settings?.rowSpacing).toFixed(2)} inches.
      </div>)}
      { settings?.columnSpacing && 
      (<div className="input-warning">
        Project column spacing will be updated to {metersToInches(settings?.columnSpacing).toFixed(2)} inches.
      </div>)}
    </>
  );
}

